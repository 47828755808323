import { __assign, __extends } from "tslib";
import * as React from 'react';
import { createDebugLogger } from '../utils/app-utils';
import { withRouter } from 'react-router-dom';
import * as tickerWorkerFile from 'file-loader?name=[name].js!../../../worker.ticker';
import { selector } from '../reducer';
import { connect } from 'react-redux';
import { WsMessage } from 'mm-ts-utils-client';
import { appWscInstance } from '../../../_library/utils/app-ws-client';
import { matchPath } from 'react-router';
import { url } from '../url';
var HeartBeatClass = /** @class */ (function (_super) {
    __extends(HeartBeatClass, _super);
    function HeartBeatClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('HeartBeat');
        _this._initTicker = function () {
            var interval = HeartBeatClass.getInterval(_this);
            if (!interval || !window.Worker) {
                return;
            }
            _this.ticker = new Worker(tickerWorkerFile);
            // register tick listener
            _this.ticker.addEventListener('message', function (message) {
                if (message.data === 'tick') {
                    _this.onTick();
                }
            });
            // start ticker
            _this.ticker.postMessage({ command: 'start', interval: interval });
        };
        _this.onTick = function () {
            if (!HeartBeatClass.getInterval(_this)) {
                return;
            }
            var _a = _this.props, identity = _a.identity, location = _a.location;
            // we need to manually parse props.location since we are outside of matched
            // route here (so cmp.props.match will not work)
            // ugly part is, that the second parameter needs to be manually synced with
            // existing threadId-relevant routes
            var params = (matchPath(location.pathname, [
                url.CHAT(':threadId?'),
                url.MESSAGE(':threadId?', ':messageId?'),
            ]) || { params: {} }).params;
            var threadId = params.threadId;
            //
            var payload = {
                identity: identity,
                url: window.location.href,
                clientTimestamp: new Date(),
                threadId: threadId,
            };
            // handle via externals completely...
            if (typeof HeartBeatClass.onTick === 'function') {
                HeartBeatClass.onTick(_this, payload);
            }
            //
            else {
                var wsc = appWscInstance();
                if (wsc && wsc.isOpen()) {
                    // handle via externals
                    if (typeof HeartBeatClass.getTickPayload === 'function') {
                        payload = __assign(__assign({}, payload), (HeartBeatClass.getTickPayload(_this) || {}));
                    }
                    wsc.send(WsMessage.factory({ type: WsMessage.TYPE_HEARTBEAT, payload: payload }));
                }
            }
        };
        return _this;
    }
    /**
     * ZERO MEANS HEARBEAT IS DISABLED!
     * Be carefull with small amount as it may heavily increase server load (would
     * of course depend on server processing and number of clients connected)
     * NOTE: overidable via externals
     * @param cmp
     */
    HeartBeatClass.getInterval = function (cmp) {
        return 0;
    };
    HeartBeatClass.prototype.componentDidMount = function () {
        this._initTicker();
    };
    HeartBeatClass.prototype.componentWillUnmount = function () {
        this.ticker && this.ticker.terminate();
    };
    HeartBeatClass.prototype.render = function () {
        return null;
    };
    return HeartBeatClass;
}(React.Component));
export { HeartBeatClass };
var mapStateToProps = function (state, ownProps) {
    return {
        identity: selector.identity.getIdentity(state),
    };
};
var mapDispatchToProps = function (dispatch, ownProps) { return ({
    dispatch: dispatch,
}); };
export var HeartBeat = withRouter(connect(mapStateToProps, mapDispatchToProps)(HeartBeatClass));
