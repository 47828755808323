import { __extends } from "tslib";
import * as Sentry from '@sentry/react';
import * as React from 'react';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { error: null, errorInfo: null };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({ error: error, errorInfo: errorInfo });
        // You can also log error messages to an error reporting service here
        console.error(error, errorInfo);
        if (process.env.SENTRY_DSN) {
            Sentry.withScope(function (scope) {
                scope.setExtra('errorStack', error.stack);
                scope.setExtra('componentStack', errorInfo.componentStack);
                scope.setLevel(Sentry.Severity.Error);
                Sentry.captureException(error);
            });
        }
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.errorInfo) {
            // Error path
            return (React.createElement("pre", { style: {
                    padding: '10px',
                    fontSize: '13px',
                    background: '#ffc',
                } },
                React.createElement("strong", { style: { color: 'darkred' } }, "Error"),
                React.createElement("details", { style: { whiteSpace: 'pre-wrap', color: 'gray' } },
                    this.state.error && this.state.error.toString(),
                    React.createElement("br", null),
                    this.state.errorInfo.componentStack)));
        }
        // Normally, just render children
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export { ErrorBoundary };
