import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { reducer } from './reducer';
export function factoryStore(initialState) {
    // https://github.com/zalmoxisus/redux-devtools-extension#installation
    var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    var logger = createLogger({
        collapsed: true,
        diff: true,
        level: { prevState: false, nextState: false },
        colors: {
            title: false,
            prevState: false,
            action: false,
            nextState: false,
            error: false,
        },
    });
    var middlewares = [thunk, promise];
    if (process.env.NODE_ENV === 'development')
        middlewares.push(logger);
    var enhancers = composeEnhancers(applyMiddleware.apply(void 0, middlewares));
    if (process.env.SENTRY_DSN) {
        enhancers = composeEnhancers(applyMiddleware.apply(void 0, middlewares), Sentry.createReduxEnhancer());
    }
    return createStore(reducer, initialState, enhancers);
}
