import { __assign, __awaiter, __extends, __generator } from "tslib";
import cn from 'classnames';
import { mmUid } from 'mm-ts-utils';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { handleThreadHeartbeatOnComponentMount, handleThreadHeartbeatOnComponentUnmount, handleThreadHeartbeatOnComponentUpdate, } from '../../../../_library/utils/thread-heartbeat';
import { ARBITRARY_ID_INITIAL_DATA, DOMAIN_TYPE_ARBITRARY, DOMAIN_TYPE_CLIENT_NOTIFICATION, GOODBOT_USER_ID, NOTIFICATION_ID__THREAD_CREATED, } from '../../../../consts';
import { chatAction, chatActionType, chatSelector } from '../../../chat/chat-redux';
import ChatThread from '../../../chat/components/ChatThread/ChatThread';
import Messages from '../../../chat/components/Messages/Messages';
import { domainAction, domainSelector, } from '../../../domain/domain-redux';
import { dispatchIsThreadActive } from '../../../domain/ui-state';
import { identitySelector } from '../../../identity/identity-redux';
import { config } from '../../config';
import { url } from '../../url';
import { getSessionThreadId } from '../../utils/app-storage';
import { createDebugLogger } from '../../utils/app-utils';
import { I18nMsgNs } from '../../utils/i18n-helpers';
import { PMA_REJECTED, pmaResetExistingMark } from '../../utils/pma-reducer';
import './PageChat.scss';
var Msg = I18nMsgNs('PageChat');
var PageChatClass = /** @class */ (function (_super) {
    __extends(PageChatClass, _super);
    function PageChatClass(props) {
        var _this = _super.call(this, props) || this;
        _this.debug = createDebugLogger('PageChat');
        _this._tns = function (id, values, defaultMessage) {
            return _this.props.intl.formatMessage({ id: "PageChat.".concat(id), defaultMessage: defaultMessage }, values);
        };
        // get initial data
        var threadId = _this.props.match.params.threadId;
        console.log('🚀 ~ file: PageChat.tsx ~ line 75 ~ PageChatClass ~ constructor ~ threadId', threadId);
        var initialData = props.initialData;
        console.log('🚀 ~ file: PageChat.tsx ~ line 80 ~ PageChatClass ~ constructor ~ initialData', initialData);
        if (initialData.threadId &&
            !window.location.pathname.includes(initialData.threadId)) {
            _this.props.history.push(url.CHAT(initialData.threadId));
            return _this;
        }
        // NOTE: nizsie mozno bude treba zahodit... lebo co
        // toto je skaredy reload, ale riesi to ojedinely use-case, kedy sa
        // (manualne) zada "/chat" url napriek tomu, ze exisuje STID
        // NOTE: history.push situaciu neriesi...
        var existingStid = getSessionThreadId(false);
        if (existingStid && !threadId) {
            // this.props.history.push(url.CHAT(existingStid));
            window.location.href = url.CHAT(existingStid);
            return _this;
        }
        return _this;
    }
    PageChatClass.resetStatics = function () {
        PageChatClass.threadIdHandler = void 0;
    };
    PageChatClass.prototype._fetchCreateOrRead = function (threadId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, dispatch, identity;
            return __generator(this, function (_b) {
                _a = this.props, dispatch = _a.dispatch, identity = _a.identity;
                if (!threadId) {
                    // vytvarame novy threadId
                    dispatch(chatAction.createThread(identity));
                }
                else {
                    dispatch(chatAction.readThread(identity, threadId));
                }
                return [2 /*return*/];
            });
        });
    };
    PageChatClass.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var threadId, initialData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        threadId = this.props.match.params.threadId;
                        initialData = this.props.initialData;
                        if (!!initialData.threadId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._fetchCreateOrRead(threadId)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        handleThreadHeartbeatOnComponentMount(threadId);
                        return [2 /*return*/];
                }
            });
        });
    };
    PageChatClass.prototype.componentDidUpdate = function (prevProps, prevState) {
        return __awaiter(this, void 0, void 0, function () {
            var threadId, _a, createdThread, dispatch, history, initialData, prevThreadId, breakFlow, newData, id;
            var _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        threadId = this.props.match.params.threadId;
                        _a = this.props, createdThread = _a.createdThread, dispatch = _a.dispatch, history = _a.history, initialData = _a.initialData;
                        prevThreadId = prevProps.match.params.threadId;
                        handleThreadHeartbeatOnComponentUpdate(threadId, prevThreadId);
                        if (typeof PageChatClass.threadIdHandler === 'function') {
                            breakFlow = PageChatClass.threadIdHandler(this, threadId, prevThreadId);
                            if (breakFlow) {
                                return [2 /*return*/];
                            }
                        }
                        if (!(threadId !== prevThreadId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._fetchCreateOrRead(threadId)];
                    case 1: return [2 /*return*/, _d.sent()];
                    case 2:
                        // ak mame notifikaciu a nemame threadId, tak sme najskor prave vytvorili...
                        if (createdThread && createdThread.payload.thread_id) {
                            // tento garbage collect funkcne (aktualne) nema zmysel iny ako udrzovanie poriadku
                            dispatch(domainAction.deleteModelByEntityTypeAndId(DOMAIN_TYPE_CLIENT_NOTIFICATION, createdThread.id));
                            history.push(url.CHAT(createdThread.payload.thread_id));
                        }
                        // ak mame threadId tak si saveneme ui info, ze sme v aktivnom threade
                        if (threadId) {
                            dispatchIsThreadActive(dispatch, true);
                        }
                        if (initialData.threadId === threadId) {
                            newData = __assign({}, initialData);
                            newData.threadId = '';
                            id = ARBITRARY_ID_INITIAL_DATA;
                            // tu pouzijeme standarnu "domain model" konvenciu a nase inital data si
                            // ulozime ako domenovy typ "ARBITRARY" a id zaznamu bude "initial_data"
                            dispatch(domainAction.mergeModelsPayload((_b = {},
                                _b[DOMAIN_TYPE_ARBITRARY] = (_c = {}, _c[id] = __assign(__assign({}, newData), { id: id }), _c),
                                _b)));
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PageChatClass.prototype.componentWillUnmount = function () {
        var dispatch = this.props.dispatch;
        var threadId = this.props.match.params.threadId;
        dispatchIsThreadActive(dispatch, false);
        // toto je len pre poriadok, v zasade nema velky vyznam (potencialne pre PageMessage)
        dispatch(pmaResetExistingMark(chatActionType.FETCH_READ_THREAD, threadId));
        handleThreadHeartbeatOnComponentUnmount(threadId);
    };
    PageChatClass.prototype.render = function () {
        var B = config.css.B('-page-chat');
        var threadId = this.props.match.params.threadId;
        var _a = this.props, fetchStatus = _a.fetchStatus, fetchError = _a.fetchError;
        var _render = function () {
            var _a;
            if (fetchStatus === PMA_REJECTED) {
                // throw fetchError;
                var messages = [
                    'Sorry, unexpected error happened. ' +
                        'Please, report the problem with the details below.',
                    typeof fetchError === 'object' && ((_a = fetchError.payload) === null || _a === void 0 ? void 0 : _a.error),
                    fetchError.toString(),
                ];
                return (React.createElement(Messages, { messages: messages.map(function (body) { return ({
                        id: mmUid(),
                        body: body,
                        user_id: GOODBOT_USER_ID,
                        created: new Date(),
                    }); }) }));
            }
            if (threadId) {
                return (React.createElement(ChatThread, { threadId: threadId }) // fetchStatus={fetchStatus}
                );
            }
        };
        return React.createElement("div", { className: cn(B) }, _render());
    };
    return PageChatClass;
}(React.Component));
export { PageChatClass };
// hackish...
// const _reference = {};
/**
 * @param state
 * @param ownProps
 */
var mapStateToProps = function (state, ownProps) {
    var threadId = ownProps.match.params.threadId;
    var initialData = domainSelector.getInitialData(state);
    var createdThread = domainSelector.getClientNotification(NOTIFICATION_ID__THREAD_CREATED)(state);
    var pmaSelector = threadId
        ? chatSelector.pmaReadThread(state)
        : chatSelector.pmaCreateThread(state);
    return {
        // selectors: Object.assign(_reference, _selectors),
        identity: identitySelector.getIdentity(state),
        createdThread: createdThread,
        fetchStatus: pmaSelector.status(threadId),
        fetchError: pmaSelector.lastError(threadId),
        initialData: initialData,
    };
};
/**
 * @param dispatch
 * @param ownProps
 */
var mapDispatchToProps = function (dispatch, ownProps) {
    return ({
        dispatch: dispatch,
    });
};
/**
 *
 */
export var PageChat = withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PageChatClass)));
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default PageChat;
