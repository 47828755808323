import { mmUid } from 'mm-ts-utils';
/**
 * Generates new conversation thread ID.
 * Max. length of 38 characters is request from @martin-miller.
 * Prefix "text_" is there only for better debugging and means that
 * the conversation originates from the text client.
 */
export function generateNewThreadId() {
    return ('text_' + mmUid(38)).substring(0, 38);
}
