import { useEffect, useRef } from 'react';
/** https://usehooks.com/usePrevious/ */
export function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value; // store current value in ref
    }, [value]);
    // return previous value (happens before update in useEffect above)
    return ref.current;
}
