import { __assign, __rest, __spreadArray } from "tslib";
import isEqual from 'lodash-es/isEqual';
import * as React from 'react';
import { Route } from 'react-router-dom';
// https://github.com/ReactTraining/react-router/issues/4105#issuecomment-289195202
export var withProps = function (component) {
    var rest = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        rest[_i - 1] = arguments[_i];
    }
    var finalProps = Object.assign.apply(Object, __spreadArray([{}], rest, false));
    return React.createElement(component, finalProps);
};
export var RouteWithProps = function (_a) {
    var component = _a.component, rest = __rest(_a, ["component"]);
    return (React.createElement(Route, __assign({}, rest, { render: function (routeProps) { return withProps(component, routeProps, rest); } })));
};
/**
 * @param i
 * @returns {string}
 */
var intToRGB = function (i) {
    var hex = [
        ((i >> 24) & 0xff).toString(16),
        ((i >> 16) & 0xff).toString(16),
        ((i >> 8) & 0xff).toString(16),
        (i & 0xff).toString(16), // tslint:disable-line
    ].join('');
    // Sometimes the string returned will be too short so we add zeros to pad it out,
    // which later get removed if the length is greater than six.
    hex += '000000';
    return hex.substring(0, 6);
};
export var createDebugLogger = function (namespace) {
    if (namespace === void 0) { namespace = ''; }
    if (process.env.NODE_ENV === 'production' || !namespace || !(console === null || console === void 0 ? void 0 : console.log)) {
        return Function.prototype; // it's no-op function doing nothing
    }
    else {
        return function (msg) {
            var rest = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                rest[_i - 1] = arguments[_i];
            }
            return console.log.apply(console, __spreadArray(["[".concat(namespace, "]"), msg], rest, false));
        };
    }
};
export var isInIframe = function () {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
};
/**
 * @param current
 * @param other
 */
export var getPropsDiff = function (current, other) {
    var changed = [];
    var details = {};
    Object.keys(other).forEach(function (k) {
        if (other[k] !== current[k]) {
            changed.push(k);
            details[k] = { current: current[k], other: other[k] };
        }
    });
    return changed.length ? { changed: changed, details: details } : null;
};
/**
 * @param current
 * @param other
 */
export var isRouterMatchFalsePositiveOnlyChange = function (current, other) {
    var diff = getPropsDiff(current, other);
    return (diff &&
        diff.changed.length === 1 &&
        diff.changed[0] === 'match' &&
        isEqual(current.match, other.match));
};
export var getOriginFromUrl = function (url) {
    url = parseUrl(url);
    return [
        url.protocol,
        '//',
        url.hostname,
        -1 !== [80, 443].indexOf(parseInt(url.port, 10)) ? ":".concat(url.port) : "",
    ].join('');
};
/**
 * For some reason TypeScript doesn't import `String.matchAll` from "core-js".
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/matchAll#regexp.exec_and_matchall
 */
export function matchAll(str, regexp) {
    var matches = [];
    var match;
    while ((match = regexp.exec(str)) !== null) {
        matches.push(match);
    }
    return matches;
}
export function parseUrl(url, key) {
    var out = {
        protocol: '',
        hostname: '',
        port: '',
        pathname: '',
        search: '',
        hash: '', // => "#hash"
    };
    var parser = document.createElement('a');
    parser.href = url || window.location.href;
    Object.keys(out).forEach(function (k) { return (out[k] = parser[k] || ''); });
    return key ? out[key] : out;
}
