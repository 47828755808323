export var getBaseHref = function () {
    var baseHref = '/';
    var base = document.getElementsByTagName('base')[0];
    if (base) {
        baseHref = base.getAttribute('href');
    }
    return baseHref;
};
export var url = {
    HOME: function () { return '/'; },
    CHAT: function (threadId) { return '/chat' + (threadId ? "/".concat(threadId) : ''); },
    MESSAGE: function (threadId, messageId) { return "/chat/".concat(threadId, "/").concat(messageId); },
    NOT_ALLOWED: function () { return '/_not-allowed'; },
    // CHAT_START: () => `/start`,
    // to be removed (debug only)
    YAML: function () { return "/yaml"; },
    TEST: function () { return "/test"; },
};
