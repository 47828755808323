import { __awaiter, __extends, __generator } from "tslib";
import axios from 'axios';
import throttle from 'lodash-es/throttle';
import { mmReplaceMap } from 'mm-ts-utils';
import * as React from 'react';
import { getClientConfig } from '../../../_core/utils/app-storage';
import { createDebugLogger, parseUrl } from '../../../_core/utils/app-utils';
import { I18nMsgNs } from '../../../_core/utils/i18n-helpers';
import FreeText from './FreeText';
var Msg = I18nMsgNs('ChatInput');
/**
 * Zmysel tohoto wraperu nad FreeTextom (a potencialne inymi) je prave v tom,
 * ze potencialne mozu byt rozne "context-aware" inputy... akurat treba domysliet
 * mechanizmus "informovania"
 */
var ChatInput = /** @class */ (function (_super) {
    __extends(ChatInput, _super);
    function ChatInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('ChatInput');
        return _this;
    }
    ChatInput.prototype.render = function () {
        var _this = this;
        return (React.createElement(FreeText, { submitMessage: this.props.submitMessage, placeholder: this.props.inputPlaceholder, autofocus: this.props.inputAutofocus, onIsTyping: function (isTyping, value) {
                isTyping
                    ? ChatInput.onIsTyping(_this, value)
                    : ChatInput.onIsTypingStop(_this, value);
            }, meta: this.props.meta }));
    };
    var _a;
    _a = ChatInput;
    ChatInput.defaultProps = {
        inputAutofocus: false,
    };
    // configurable via externals
    ChatInput.fetchIsTyping = function (threadId, isTyping, value) { return __awaiter(void 0, void 0, void 0, function () {
        var _url, uriTpl;
        return __generator(_a, function (_b) {
            _url = parseUrl();
            uriTpl = getClientConfig().THREAD_IS_TYPING_URI_TEMPLATE;
            if (uriTpl) {
                return [2 /*return*/, axios.put(mmReplaceMap(uriTpl, {
                        '{protocol}': _url.protocol,
                        '{hostname}': _url.hostname,
                        '{port}': _url.port,
                        '{threadId}': threadId,
                    }), { isTyping: isTyping, value: value })];
            }
            return [2 /*return*/];
        });
    }); };
    // configurable via externals
    ChatInput.onIsTyping = throttle(function (cmp, value) {
        ChatInput.fetchIsTyping(cmp.props.threadId, true, value);
    }, 2000, { trailing: false });
    // configurable via externals
    ChatInput.onIsTypingStop = function (cmp, value) {
        ChatInput.fetchIsTyping(cmp.props.threadId, false, value);
    };
    return ChatInput;
}(React.Component));
export { ChatInput };
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default ChatInput;
