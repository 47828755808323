import { mmReplaceMap } from 'mm-ts-utils';
import { WsClient } from 'mm-ts-utils-client/dist/ws/WsClient';
import { config } from '../../modules/_core/config';
import { getClientConfig } from '../../modules/_core/utils/app-storage';
import { createDebugLogger, parseUrl } from '../../modules/_core/utils/app-utils';
var wsc;
// hm... tu ide o to, ze pouzivame na urovni appky code-splitting, a tym padom
// akekolvek "single instance" mimo centralneho globalneho contextu (a.k.a. window)
// najskor nebude dost mozne (ako funguje webpack codesplitting import context uplne
// detailne neviem)... mozno nieco prehliadam, ale kazdopadne toto by nic
// nemalo pokazit
wsc = window.__app_ws_client__;
export var appWscInstance = function () {
    if (wsc === void 0) {
        var clientConfig = getClientConfig();
        var url = parseUrl();
        var wsUrl = mmReplaceMap(clientConfig.WS_URL !== void 0 ? clientConfig.WS_URL : config.ws.url, {
            '{protocol}': url.protocol === 'https:' ? 'wss:' : 'ws:',
            '{hostname}': url.hostname,
            '{port}': url.port,
        }).trim();
        // empty string means skip...
        if (!wsUrl) {
            wsc = null;
            console.log('Empty WS_URL config found - skipping websocket features. To use the default, remove WS_URL from config altogether.');
        }
        else {
            wsc = new WsClient(_buildUrlFrom(wsUrl), {
                // delay: 1000, // comment on production
                // debug: true, // comment on production
                // reconnectDataProvider: () => ({ foo: 'bar' }),
                logger: createDebugLogger('WsClient'),
            });
        }
    }
    // citaj vyssie...
    window.__app_ws_client__ = wsc;
    return wsc;
};
//
var _buildUrlFrom = function (template) {
    // let loc = window.location;
    // let protocol = loc.protocol === 'https' ? 'wss://' : 'ws://';
    // return protocol + loc.host + '/ws/';
    // let parsed = urlParse(window.location.href);
    var parsed = parseUrl(window.location.href);
    if (!parsed.port) {
        parsed.port = '80';
    }
    var tplMap = Object.keys(parsed).reduce(function (memo, k) {
        memo["{{".concat(k.toUpperCase(), "}}")] = parsed[k];
        return memo;
    }, {});
    // ws alebo wss?
    tplMap["{{WS_PROTOCOL}}"] = /https/i.test(parsed.protocol) ? 'wss:' : 'ws:';
    // port? ak posielame, tak prepiseme
    var wsPort = (window.__SERVER_DATA__ || {}).WS_PORT;
    if (wsPort) {
        tplMap['{{PORT}}'] = wsPort;
    }
    return mmReplaceMap(template, tplMap);
};
//
