import { __assign } from "tslib";
import merge from 'lodash-es/merge';
import { MMStorage } from 'mm-ts-utils-client';
import { generateNewThreadId } from '../../../_library/utils/identifiers';
import { CLIENT_CONFIG_EXPOSE_KEY } from '../../../consts';
import { config } from '../config';
import { ADAPTER_NAMES } from '../types';
var WINDOW_CLIENT_CONFIG_GLOBAL = '__CLIENT_CONFIG__';
// runtime config is populated in scripts.js endpoint
var clientConfigBuildStage = process.env.DEFAULT_CLIENT_CONFIG;
var clientConfigRuntime = window[WINDOW_CLIENT_CONFIG_GLOBAL] || {};
var clientConfig = merge(clientConfigBuildStage, clientConfigRuntime);
export var appLocalStorage = function () { return new MMStorage("".concat(config.appCode, "|"), false); };
export var appSessionStorage = function () { return new MMStorage("".concat(config.appCode, "|"), true); };
var _saveToStorage = function (key, data, type) {
    var s = /session/i.test(type) ? appSessionStorage() : appLocalStorage();
    s.setItem(key, data);
    return true;
};
var _getFromStorage = function (key, type) {
    var s = /session/i.test(type) ? appSessionStorage() : appLocalStorage();
    return s.getItem(key);
};
var _deleteFromStorage = function (key, type) {
    var s = /session/i.test(type) ? appSessionStorage() : appLocalStorage();
    return s.removeItem(key);
};
// prettier-ignore
export var saveToLocalStorage = function (key, data) { return _saveToStorage(key, data, 'local'); };
export var getFromLocalStorage = function (key) { return _getFromStorage(key, 'local'); };
export var deleteFromLocalStorage = function (key) { return _deleteFromStorage(key, 'local'); };
// prettier-ignore
export var saveToSessionStorage = function (key, data) { return _saveToStorage(key, data, 'session'); };
export var getFromSessionStorage = function (key) { return _getFromStorage(key, 'session'); };
export var deleteFromSessionStorage = function (key) { return _deleteFromStorage(key, 'session'); };
//
var SESSION_THREAD_ID_KEY = 'STID';
/**
 *
 */
export var hasSessionThreadId = function () { return !!getSessionThreadId(); };
/**
 * @param createIfNotExists
 */
export var getSessionThreadId = function (createIfNotExists) {
    if (createIfNotExists === void 0) { createIfNotExists = false; }
    var ss = appSessionStorage();
    if (createIfNotExists && !ss.getItem(SESSION_THREAD_ID_KEY)) {
        ss.setItem(SESSION_THREAD_ID_KEY, generateNewThreadId());
    }
    return ss.getItem(SESSION_THREAD_ID_KEY);
};
/**
 * @param val
 */
export var setSessionTheadId = function (val) {
    return appSessionStorage().setItem(SESSION_THREAD_ID_KEY, val);
};
/**
 *
 */
export var removeSessionThreadId = function () {
    return appSessionStorage().removeItem(SESSION_THREAD_ID_KEY);
};
/**
 *
 */
export var getClientConfig = function () {
    // doplname featuru, kde vieme zo sessny overidnut veci... handy pre debugovanie
    // a hackovanie
    var stored = appSessionStorage().getItem(CLIENT_CONFIG_EXPOSE_KEY, {});
    return merge(clientConfig, stored);
};
export var setClientConfig = function (config) {
    return (clientConfig = config);
};
/**
 *
 */
export var mergeToClientConfig = function (data) {
    return (clientConfig = merge(clientConfig, data));
};
/**
 *
 */
export var getAdapterName = function () {
    var cfg = getClientConfig();
    return (cfg.CHAT_API.ADAPTER_NAME ||
        cfg.CHAT_API.ADAPTER_NAME_FALLBACK ||
        ADAPTER_NAMES.MISSING);
};
/**
 *
 */
export var saveAsClientConfigToSession = function (cfg) {
    appSessionStorage().setItem(CLIENT_CONFIG_EXPOSE_KEY, __assign({}, (cfg || {})));
};
