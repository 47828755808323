import { __assign } from "tslib";
import { ARBITRARY_ID_UI_STATE, DOMAIN_TYPE_ARBITRARY } from '../../consts';
import { domainAction } from './domain-redux';
/**
 * @param dispatch
 * @param uiState
 */
export var dispatchUiState = function (dispatch, uiState) {
    var _a, _b;
    dispatch(domainAction.mergeModelsPayload((_a = {},
        _a[DOMAIN_TYPE_ARBITRARY] = (_b = {},
            _b[ARBITRARY_ID_UI_STATE] = __assign(__assign({}, uiState), { id: ARBITRARY_ID_UI_STATE }),
            _b),
        _a)));
};
/**
 * sugar
 * @param dispatch
 * @param flag
 */
export var dispatchIsThreadActive = function (dispatch, flag) {
    dispatchUiState(dispatch, { is_thread_active: flag });
};
