import { __extends } from "tslib";
import { BaseChatMessage, } from '../../_library/domain-base/BaseChatMessage';
import isPlainObject from 'lodash-es/isPlainObject';
var ChatMessage = /** @class */ (function (_super) {
    __extends(ChatMessage, _super);
    function ChatMessage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @param messageOrData
     */
    ChatMessage.resolveGroupLabel = function (messageOrData) {
        var message = isPlainObject(messageOrData)
            ? new ChatMessage(messageOrData)
            : messageOrData;
        if (typeof ChatMessage.groupLabelResolver === 'function') {
            return ChatMessage.groupLabelResolver(message);
        }
        // default fallback if no externals provided...
        // (this is naive in the long term - user_id would be much better, but
        // we're not using identity at the frontend level /at uc33sk/)
        return message.is_bot ? 'other' : 'user';
    };
    return ChatMessage;
}(BaseChatMessage));
export { ChatMessage };
