import { __awaiter, __extends, __generator } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { BubbleCommand } from '../../../../../../_shared/constants';
import { alertModal } from '../../../domain/app-modal';
import { domainSelector } from '../../../domain/domain-redux';
import { config } from '../../config';
import { url } from '../../url';
import { getClientConfig } from '../../utils/app-storage';
import { createDebugLogger, isInIframe } from '../../utils/app-utils';
import { BubbleChannel } from '../BubbleChannel';
import { BytesizeIcon } from '../BytesizeIcon';
import './Header.scss';
var _isFn = function (v) { return typeof v === 'function'; };
var HeaderClass = /** @class */ (function (_super) {
    __extends(HeaderClass, _super);
    function HeaderClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('Header');
        _this.debugCloseNoopMsg = [
            "You are trying to close the chat client from the top window context ",
            "(that is from not inside of a&nbsp;bubble) which is a&nbsp;\"no operation\".",
        ].join('');
        _this._handleCloseClick = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var _createXHandler, closeBubble, hideBubble;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        _createXHandler = function (command) { return function () {
                            if (!isInIframe()) {
                                return alertModal(_this.props.dispatch, { body: _this.debugCloseNoopMsg });
                            }
                            BubbleChannel.postCommand(command, null, function () {
                                _this.debug("Bubble did ".concat(command));
                            });
                        }; };
                        closeBubble = _createXHandler(BubbleCommand.CLOSE);
                        hideBubble = _createXHandler(BubbleCommand.HIDE);
                        if (!_isFn(HeaderClass.onCloseHandler)) return [3 /*break*/, 2];
                        return [4 /*yield*/, HeaderClass.onCloseHandler(this, { event: event, closeBubble: closeBubble, hideBubble: hideBubble })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        closeBubble();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    HeaderClass.prototype._renderCloseIcon = function () {
        if (_isFn(HeaderClass.rendererCloseIcon)) {
            return HeaderClass.rendererCloseIcon({
                props: this.props,
                state: this.state,
            });
        }
        var _a = (getClientConfig().HEADER || {}).CLOSE_ICON || {}, type = _a.type, options = _a.options;
        options = options || {};
        switch ("".concat(type).toLowerCase()) {
            // napr: case 'material-icons'... 'font-awesome'
            // ak by malo byt treba uplny custom, tak potom riesit cez (v poradi podla custom potrieb):
            // a) headerUtils.rendererCloseIcon
            // b) headerUtils.renderer
            // c) "external" header component
            // defaultne bundlnute je bytesize
            case 'bytesize':
            default:
                return (React.createElement(BytesizeIcon, { name: "close", size: options.size || 'small', thickness: options.thickness || 'medium', strokeLineCap: options.strokeLineCap, strokeLineJoin: options.strokeLineJoin }));
        }
    };
    HeaderClass.prototype.render = function () {
        var _this = this;
        var renderer = HeaderClass.renderer, rendererLeftBox = HeaderClass.rendererLeftBox, rendererMainBox = HeaderClass.rendererMainBox, rendererRightBox = HeaderClass.rendererRightBox;
        // completely external render?
        if (_isFn(renderer)) {
            return renderer(this);
        }
        var B = config.css.B('-header');
        var headerLabel = this.props.headerLabel;
        var cfg = getClientConfig();
        var headerCfg = cfg.HEADER || {};
        var LOGO_SRC_URL = cfg.LOGO_SRC_URL, PROJECT_TITLE = cfg.PROJECT_TITLE;
        headerLabel = headerLabel || PROJECT_TITLE;
        // prioritu ma HEADER ale podporujeme aj staru notaciu
        LOGO_SRC_URL = headerCfg.LOGO_SRC_URL || LOGO_SRC_URL;
        var closeIconEnabled = (headerCfg.CLOSE_ICON || {}).enabled;
        if (closeIconEnabled === void 0) {
            closeIconEnabled = true;
        }
        var _renderLeft = function () { return (_isFn(rendererLeftBox) ? rendererLeftBox(_this) : null); };
        var _renderMain = function () {
            return _isFn(rendererMainBox) ? (rendererMainBox(_this)) : (React.createElement("h1", null,
                React.createElement(Link, { className: cn("".concat(B, "__logobox")), to: url.HOME() }, LOGO_SRC_URL ? (React.createElement("img", { src: LOGO_SRC_URL, alt: "".concat(headerLabel, " (logo image)") })) : (React.createElement("span", null, headerLabel)))));
        };
        var _renderRight = function () {
            if (_isFn(rendererRightBox)) {
                return rendererRightBox(_this);
            }
            if (closeIconEnabled) {
                return (React.createElement("button", { className: cn("".concat(B, "__boxbutton")), onClick: _this._handleCloseClick }, _this._renderCloseIcon()));
            }
            return null;
        };
        return (React.createElement("div", { className: cn(B) },
            React.createElement("div", { className: cn("".concat(B, "__leftbox")) }, _renderLeft()),
            React.createElement("div", { className: cn("".concat(B, "__mainbox")) }, _renderMain()),
            React.createElement("div", { className: cn("".concat(B, "__rightbox")) }, _renderRight())));
    };
    HeaderClass.defaultProps = {
        isThreadActive: false,
    };
    return HeaderClass;
}(React.Component));
export { HeaderClass };
var mapStateToProps = function (state, ownProps) {
    var uiState = domainSelector.getUIState(state);
    var isAgent = domainSelector.getOppositeParty(state);
    return {
        headerLabel: uiState.header,
        isThreadActive: uiState.is_thread_active,
        isAgent: isAgent,
        showConfirmOnBubbleClose: uiState.show_confirm_on_bubble_close,
        getLastThreadMessage: domainSelector.getLastThreadMessage(state),
        initialData: domainSelector.getInitialData(state),
    };
};
var mapDispatchToProps = function (dispatch, ownProps) {
    return ({
        dispatch: dispatch,
    });
};
var connected = connect(mapStateToProps, mapDispatchToProps)(HeaderClass);
export var Header = withRouter(connected);
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default Header;
