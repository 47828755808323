var _this = this;
import { __assign } from "tslib";
import cn from 'classnames';
import debounce from 'lodash-es/debounce';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch, } from 'react-router-dom';
import { SCREEN_SMALL_WIDTH_BREAKPOINT_PX } from '../../../../consts';
import { domainSelector } from '../../../domain/domain-redux';
import { config } from '../../config';
import { routes } from '../../routes';
import { url } from '../../url';
import { getClientConfig } from '../../utils/app-storage';
import { createDebugLogger, isInIframe } from '../../utils/app-utils';
import { PageHome } from '../PageHome/PageHome';
import './App.scss';
var CONFIG = getClientConfig();
var debug = createDebugLogger('App');
var App = function () {
    var _a;
    var location = useLocation();
    var isBubbleOpen = useSelector(domainSelector.getUIState).isBubbleOpen;
    var history = useHistory();
    var _b = useRouteMatch(), path = _b.path, params = _b.params;
    var uiState = useSelector(domainSelector.getUIState);
    var _c = useState(getScreenSize()), screenSize = _c[0], setScreenSize = _c[1];
    useEffect(function () {
        document.title = CONFIG.HTML_TITLE;
    }, [CONFIG.HTML_TITLE]);
    useEffect(function () {
        var _debouncedSetScreenSize = debounce(function () { return setScreenSize(getScreenSize()); }, 100);
        window.addEventListener('resize', _debouncedSetScreenSize);
        return function () { return window.removeEventListener('resize', _debouncedSetScreenSize); };
    }, [setScreenSize]);
    useEffect(function () {
        if ((!isInIframe() || isBubbleOpen) && !params.threadId) {
            // if we have a custom route from externals, use it...
            var route = PageHome.getInitialRoute();
            if (route !== path) {
                debug("Initial route via externals detected: ".concat(route));
                history.push(route);
            }
        }
        else if (!isBubbleOpen) {
            history.push(url.HOME());
        }
    }, [isBubbleOpen, Boolean(params.threadId)]);
    var rendered = App.renderer(_this);
    if (rendered) {
        return rendered;
    }
    var B = config.css.B('-app');
    var isSmallApp = screenSize.width <= SCREEN_SMALL_WIDTH_BREAKPOINT_PX;
    var parentScreenWidth = ((_a = uiState.parent_screen_size) === null || _a === void 0 ? void 0 : _a.screenWidth) || 0;
    var isSmallParent = parentScreenWidth <= SCREEN_SMALL_WIDTH_BREAKPOINT_PX;
    return (React.createElement("div", { className: cn(B, {
            'app-smallwidth': isSmallApp,
            'parent-smallwidth': isSmallParent,
        }) },
        React.createElement(Switch, { location: location },
            App.externalRoutes.concat(routes).map(function (route, i) { return (React.createElement(Route, __assign({ key: i }, route))); }),
            React.createElement(Route, { component: NoMatch }))));
};
App.renderer = function () { return null; };
App.externalRoutes = [];
function getScreenSize() {
    return {
        width: window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth,
        height: window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
    };
}
function NoMatch() {
    return (React.createElement("div", { style: { padding: '20px' } },
        "Page not found. Continue to ",
        React.createElement(Link, { to: url.HOME() }, "homepage"),
        "."));
}
export default App;
