import { __awaiter, __extends, __generator } from "tslib";
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { handleThreadHeartbeatOnComponentMount, handleThreadHeartbeatOnComponentUnmount, } from '../../../../_library/utils/thread-heartbeat';
import { CHAT_MESSAGE_DISPLAY_CONTEXT_STANDALONE } from '../../../../consts';
import { chatAction, chatActionType, chatSelector } from '../../../chat/chat-redux';
import { ChatMessage } from '../../../chat/ChatMessage';
import { ChatThreadClass } from '../../../chat/components/ChatThread/ChatThread';
import { MessagesClass } from '../../../chat/components/Messages/Messages';
import { domainSelector } from '../../../domain/domain-redux';
import { identitySelector } from '../../../identity/identity-redux';
import { createDebugLogger } from '../../utils/app-utils';
import { I18nMsgNs } from '../../utils/i18n-helpers';
import { PMA_FULFILLED, PMA_NONE, pmaResetExistingMark } from '../../utils/pma-reducer';
var Msg = I18nMsgNs('PageMessage');
var PageMessageClass = /** @class */ (function (_super) {
    __extends(PageMessageClass, _super);
    function PageMessageClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('PageMessage');
        return _this;
    }
    PageMessageClass.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, message, dispatch, identity, threadId;
            return __generator(this, function (_b) {
                _a = this.props, message = _a.message, dispatch = _a.dispatch, identity = _a.identity;
                threadId = this.props.match.params.threadId;
                if (!message) {
                    dispatch(chatAction.readThread(identity, threadId));
                }
                handleThreadHeartbeatOnComponentMount(threadId);
                return [2 /*return*/];
            });
        });
    };
    PageMessageClass.prototype.componentWillUnmount = function () {
        var _a = this.props, dispatch = _a.dispatch, fetchStatus = _a.fetchStatus;
        var threadId = this.props.match.params.threadId;
        // cleanup
        if (fetchStatus !== PMA_NONE) {
            dispatch(pmaResetExistingMark(chatActionType.FETCH_READ_THREAD, threadId));
        }
        handleThreadHeartbeatOnComponentUnmount(threadId);
    };
    PageMessageClass.prototype.render = function () {
        var _a = this.props, fetchStatus = _a.fetchStatus, fetchError = _a.fetchError, message = _a.message, identity = _a.identity, dispatch = _a.dispatch;
        var _b = this.props.match.params, threadId = _b.threadId, messageId = _b.messageId;
        if (!message) {
            if (fetchError) {
                console.error("PageMessage: ".concat(fetchStatus, " ").concat(fetchError.toString()));
            }
            if (fetchStatus === PMA_FULFILLED) {
                return React.createElement("div", null, "Message not found!");
            }
            return null;
        }
        var cmpProps = {
            identity: identity,
            displayContextIndex: 0,
            displayContext: CHAT_MESSAGE_DISPLAY_CONTEXT_STANDALONE,
            submitMessage: function (value, type) {
                return ChatThreadClass.submitMessage({ dispatch: dispatch, identity: identity, threadId: threadId }, value, type);
            },
        };
        return MessagesClass.getMessageComponent(message, cmpProps);
    };
    return PageMessageClass;
}(React.Component));
var mapStateToProps = function (state, ownProps) {
    var _a = ownProps.match.params, threadId = _a.threadId, messageId = _a.messageId;
    var messageData = domainSelector.getThreadMessage(state)(threadId, messageId);
    var message = messageData ? new ChatMessage(messageData) : null;
    var pmaSelector = chatSelector.pmaReadThread(state);
    return {
        identity: identitySelector.getIdentity(state),
        message: message,
        fetchStatus: pmaSelector.status(threadId),
        fetchError: pmaSelector.lastError(threadId),
    };
};
var mapDispatchToProps = function (dispatch, ownProps) {
    return ({
        dispatch: dispatch,
    });
};
export var PageMessage = withRouter(connect(mapStateToProps, mapDispatchToProps)(PageMessageClass));
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default PageMessage;
