import { mmUcfirst } from 'mm-ts-utils';
import { getLoadableComponent } from '../../../../_library/utils/get-loadable-component';
var _formatWidgetName = function (str) {
    return mmUcfirst(str
        .replace(/[_-](\w)/g, function (match, p1) {
        return match.slice(0, -1) + p1.toUpperCase();
    })
        .replace(/[-_]/g, ''));
};
export var WidgetWrapper = function (props) {
    var message = props.message;
    // note that `Widget` suffix is important - helps to avoid the possibility
    // to 'steal' other components names (regardless of good/bad intentions)
    var widgetCls = _formatWidgetName(message.body) + 'Widget';
    // nakoniec nevraciame ziaden wrap markup...
    return getLoadableComponent(widgetCls, props);
};
