import { BubbleMsg } from '../_shared/constants';
import { BubbleChannelClass } from '../client/src/modules/_core/components/BubbleChannel';
import { isInIframe } from '../client/src/modules/_core/utils/app-utils';
// to be improved if needed
export var isJuro = function () {
    var rx = /juro[.\-_]sk/i;
    return rx.test(window.location.href);
};
export var dataLayerPush = function (obj) {
    obj = obj || {};
    // DRY
    obj.event = obj.event || 'ChatClicks';
    obj.eventCategory = obj.eventCategory || 'Click';
    var debug = 'dataLayer.push(' + JSON.stringify(obj) + ')';
    if (window.dataLayer) {
        console.log(debug);
        window.dataLayer.push(obj);
    }
    else if (isInIframe()) {
        BubbleChannelClass.parentBubblePostMessage({
            type: BubbleMsg.DATA_LAYER_PUSH,
            payload: obj,
        });
    }
    else {
        console.warn('dataLayer not available... ' + debug);
    }
};
