import { __assign } from "tslib";
/*********************************************************************************
 * ACTION TYPES
 ********************************************************************************/
import produce from 'immer';
export var identityActionType = {
    SET_IDENTITY: 'identity/SET_IDENTITY',
};
/*********************************************************************************
 * ACTIONS (ACTION CREATORS)
 ********************************************************************************/
export var identityAction = {
    setIdentity: function (payload) {
        // IMPORTANT SPECIAL CASE: last_known_heartbeat neukladame (aby sa
        // nerenderovalo pri kazdom update)
        if (payload) {
            payload.last_known_heartbeat = null; // DOLEZITE, vid tiez WsChannel
        }
        return {
            type: identityActionType.SET_IDENTITY,
            payload: payload,
        };
    },
};
/*********************************************************************************
 * SELECTORS
 ********************************************************************************/
//
export var identitySelector = {
    getIdentity: function (state) { return state.identity; },
    // v skutocnosti len hovori, ze ma token... ci je platny overuje server
    isAuthenticated: function (state) {
        return state.identity && state.identity.api_token && state.identity.id;
    },
};
var createIdentityInitialState = function () { return null; };
export var identityReducer = produce(function (state, action) {
    switch (action.type) {
        case identityActionType.SET_IDENTITY:
            var identity = action.payload;
            return identity ? __assign(__assign({}, state), identity) : null;
    }
}, createIdentityInitialState());
