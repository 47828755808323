import { __extends } from "tslib";
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createDebugLogger } from '../../../_core/utils/app-utils';
import { I18nMsgNs } from '../../../_core/utils/i18n-helpers';
var Msg = I18nMsgNs('ChatThreadDebugInfo');
var ChatThreadDebugInfoClass = /** @class */ (function (_super) {
    __extends(ChatThreadDebugInfoClass, _super);
    function ChatThreadDebugInfoClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('ChatThreadDebugInfo');
        return _this;
    }
    ChatThreadDebugInfoClass.prototype.render = function () {
        var renderer = ChatThreadDebugInfoClass.renderer;
        if (typeof renderer === 'function') {
            return renderer(this);
        }
        return null;
    };
    return ChatThreadDebugInfoClass;
}(React.Component));
export { ChatThreadDebugInfoClass };
var mapStateToProps = function (state, ownProps) {
    return {};
};
var mapDispatchToProps = function (dispatch, ownProps) {
    return ({
        dispatch: dispatch,
    });
};
export var ChatThreadDebugInfo = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatThreadDebugInfoClass));
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default ChatThreadDebugInfo;
