import { __extends } from "tslib";
import { BaseUser } from '../../_library/domain-base/BaseUser';
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    User.prototype.toJSONApi = function () {
        var jsonapi = _super.prototype.toJSONApi.call(this);
        delete jsonapi.attributes.email;
        return jsonapi;
    };
    return User;
}(BaseUser));
export { User };
