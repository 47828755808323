export var getThreadMessages = function (state) {
    return function (threadId) {
        var messagesMap = state.domain.chat_message || {};
        return Object.keys(messagesMap)
            .reduce(function (memo, k) {
            var m = messagesMap[k];
            if (m.thread_id === threadId) {
                memo.push(m);
            }
            return memo;
        }, [])
            .sort(function (a, b) { return new Date(a.created).valueOf() - new Date(b.created).valueOf(); });
    };
};
