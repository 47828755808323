import { __assign, __extends, __rest } from "tslib";
import cn from 'classnames';
import { mmParseQuery } from 'mm-ts-utils';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import breaks from 'remark-breaks';
import { CHAT_MESSAGE_DISPLAY_CONTEXT_DEFAULT, CHAT_MESSAGE_DISPLAY_CONTEXT_STANDALONE, } from '../../../../consts';
import { BytesizeIcon } from '../../../_core/components/BytesizeIcon';
import { config } from '../../../_core/config';
import { getAdapterName } from '../../../_core/utils/app-storage';
import { createDebugLogger, parseUrl } from '../../../_core/utils/app-utils';
import { I18nMsgNs } from '../../../_core/utils/i18n-helpers';
import { chatSelector } from '../../chat-redux';
import { ChatMessage } from '../../ChatMessage';
import { Options } from './Options/Options';
import './TextMessage.scss';
var _isFn = function (v) { return typeof v === 'function'; };
var debug = createDebugLogger('TextMessage');
var Msg = I18nMsgNs('TextMessage');
var TextMessageClass = /** @class */ (function (_super) {
    __extends(TextMessageClass, _super);
    function TextMessageClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('TextMessage');
        return _this;
    }
    TextMessageClass.prototype.render = function () {
        switch (this.props.displayContext) {
            // typicky v PageMessage
            case CHAT_MESSAGE_DISPLAY_CONTEXT_STANDALONE:
                return this._renderDisplayContextMessage();
            // doplnat podla potreby...
            // case SOME...:
            // vsetko ostatne
            case CHAT_MESSAGE_DISPLAY_CONTEXT_DEFAULT:
            default:
                return this._renderDisplayContextDefault();
        }
    };
    TextMessageClass.prototype._renderDisplayContextDefault = function () {
        var _this = this;
        // external renderer?
        if (_isFn(TextMessageClass.rendererContextDefault)) {
            return TextMessageClass.rendererContextDefault(this);
        }
        var message = this.props.message;
        var B = config.css.B('-text-message');
        var authorType = message.authorType;
        var _a = this.props, fetchStatus = _a.fetchStatus, displayGroupContextIndex = _a.displayGroupContextIndex, displayGroupContextLength = _a.displayGroupContextLength;
        var hasOptions = !!(message.meta.options && message.meta.options.length);
        var groupLabel = ChatMessage.resolveGroupLabel(message);
        // textovy message ak ma prazdne telo a neobsahuje options, tak uplne skipujem
        // JE TOTO LEGITIMNY STAV?
        if (!message.body && !message.meta.options) {
            console.warn("Ignoring empty message body... (id: ".concat(message.id, ")"));
            return null;
        }
        var _maybeRenderOptions = function () {
            if (!hasOptions) {
                return null;
            }
            // todo: rename to TextMessageOptions and refactor as loadable
            return (React.createElement("div", { className: "".concat(B, "__inner"), "data-author-type": authorType, "data-has-options": hasOptions, "data-group-label": ChatMessage.resolveGroupLabel(message), "data-group-idx": displayGroupContextIndex, "data-group-first": displayGroupContextIndex === 0, "data-group-last": displayGroupContextIndex === displayGroupContextLength - 1 },
                React.createElement(Options, { message: message, options: message.meta.options, data: message.meta.data, submitMessage: _this.props.submitMessage, disabled: !_this._isLastNonTransient() })));
        };
        var _maybeRenderBody = function () {
            if (!message.body) {
                return null;
            }
            var components = {};
            if (_isFn(TextMessageClass.messageBodyMarkdownLinkRenderer)) {
                components.a = TextMessageClass.messageBodyMarkdownLinkRenderer;
            }
            components.img = function (_a) {
                var src = _a.src, alt = _a.alt, props = __rest(_a, ["src", "alt"]);
                // We use similar syntax as markdown images: ![video](url)
                if (alt === 'video') {
                    return React.createElement(ReactPlayer, { url: src, width: "100%", height: "200", controls: true });
                }
                // podporujeme zapis "image.jpg?__size__=100x200"
                var parsed = parseUrl(src);
                if (parsed.search) {
                    var query = mmParseQuery(parsed.search.substr(1));
                    if (query.__size__) {
                        var _b = query.__size__.split('x').map(function (v) {
                            v = parseInt(v, 10);
                            return !isNaN(v) && v > 0 ? v : 0;
                        }), width = _b[0], height = _b[1];
                        width && (props.width = width);
                        height && (props.height = height);
                    }
                }
                return React.createElement("img", __assign({ src: src, alt: alt }, props));
            };
            // meta parse...
            var meta = message.meta || {};
            if (typeof meta === 'string') {
                try {
                    meta = JSON.parse(meta);
                }
                catch (e) {
                    debug("Message meta is not valid JSON: ".concat(JSON.stringify(message.meta)));
                }
            }
            var authorName = React.createElement(Msg, { id: authorType });
            if (meta.author_name) {
                authorName = meta.author_name;
            }
            var _renderAvatar = _isFn(TextMessageClass.rendererAvatar)
                ? TextMessageClass.rendererAvatar
                : function (cmp) {
                    // return <i data-author-type={authorType} />;
                    return (React.createElement("i", { "data-author-type": authorType },
                        React.createElement(BytesizeIcon, { name: message.is_bot ? 'code' : 'user', size: "smallest" })));
                };
            var adapterName = getAdapterName();
            var _messageTimestampFormatter = _isFn(TextMessageClass.messageTimestampFormatter)
                ? TextMessageClass.messageTimestampFormatter
                : function (message, cmp) {
                    var d = new Date(message.created);
                    var pad = function (v) { return (v < 10 ? "0".concat(v) : v); };
                    return pad(d.getHours()) + ':' + pad(d.getMinutes());
                };
            var _rendererTimestamp = _isFn(TextMessageClass.rendererMessageTimestamp)
                ? TextMessageClass.rendererMessageTimestamp
                : function (cmp) {
                    return (React.createElement("small", { className: "".concat(B, "__inner__created"), "data-author-type": authorType, "data-fetch-status": fetchStatus, "data-adapter": adapterName }, _messageTimestampFormatter(cmp.props.message, cmp)));
                };
            // const authorName = message.meta.author_name != null ? message.meta.author_name : <Msg id={authorType} />;
            // prettier-ignore
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: cn("".concat(B, "__avatar")), "data-author-type": authorType, "data-group-label": groupLabel, "data-group-idx": displayGroupContextIndex, "data-group-first": displayGroupContextIndex === 0, "data-group-last": displayGroupContextIndex === displayGroupContextLength - 1, "data-fetch-status": fetchStatus, "data-adapter": adapterName }, _renderAvatar(_this)),
                React.createElement("div", { className: "".concat(B, "__inner"), "data-author-type": authorType, "data-group-label": groupLabel, "data-fetch-status": fetchStatus, "data-adapter": adapterName, "data-has-options": hasOptions, "data-group-idx": displayGroupContextIndex, "data-group-first": displayGroupContextIndex === 0, "data-group-last": displayGroupContextIndex === displayGroupContextLength - 1 },
                    React.createElement("div", { className: "".concat(B, "__inner__author"), "data-author-type": authorType, "data-fetch-status": fetchStatus },
                        React.createElement("strong", null, authorName)),
                    React.createElement("div", { className: "".concat(B, "__inner__message"), "data-author-type": authorType, "data-fetch-status": fetchStatus, "data-adapter": adapterName },
                        React.createElement(ReactMarkdown, { linkTarget: "_blank", components: components, remarkPlugins: [breaks] }, message.body)),
                    _rendererTimestamp(_this))));
        };
        // prettier-ignore
        return (React.createElement("div", { className: cn(B), "data-author-type": authorType, "data-group-label": groupLabel, "data-message-type": message.type, "data-message-id": message.id, "data-fetch-status": fetchStatus, "data-has-options": hasOptions },
            _maybeRenderBody(),
            _maybeRenderOptions()));
    };
    TextMessageClass.prototype._renderDisplayContextMessage = function () {
        // external renderer?
        if (_isFn(TextMessageClass.rendererContextStandalone)) {
            return TextMessageClass.rendererContextStandalone(this);
        }
        var message = this.props.message;
        // for debugging basically
        return (React.createElement("pre", { style: { width: '100%', margin: '0', fontSize: '12px' } },
            '\n\n    ',
            React.createElement("b", null, "Not expecting to see this? Please let us know."),
            '\n\n',
            JSON.stringify(message.toJSON(), null, 2)));
    };
    // toto je
    TextMessageClass.prototype._isLastNonTransient = function () {
        var _a = this.props, displayContextIndex = _a.displayContextIndex, messages = _a.messages, message = _a.message;
        // CHAT_MESSAGE_DISPLAY_CONTEXT_DEFAULT
        if (messages && messages.length) {
            for (var i = displayContextIndex + 1; i < messages.length; i++) {
                var m = new ChatMessage(messages[i]);
                if (!m.isTransientLike) {
                    return false;
                }
            }
            return true;
        }
        // CHAT_MESSAGE_DISPLAY_CONTEXT_STANDALONE
        return !message.isTransientLike;
    };
    return TextMessageClass;
}(React.Component));
export { TextMessageClass };
var mapStateToProps = function (state, ownProps) {
    var message = ownProps.message;
    var pmaSelector = chatSelector.pmaSaveMessage(state);
    return {
        fetchStatus: pmaSelector.status(message.id),
    };
};
var mapDispatchToProps = function (dispatch, ownProps) {
    return ({
        dispatch: dispatch,
    });
};
export var TextMessage = connect(mapStateToProps, mapDispatchToProps)(TextMessageClass);
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default TextMessage;
