import { __assign } from "tslib";
/**
 * UPDATE: since the main design concept here (beforeunload) does not work on
 * iPhone's webview we're refactoring this with client's generic heartbeat.
 * If it proves to be working fine, this will become deprecated.
 *
 * This is mechanism how to end abandoned chat. In case the user clicks to another
 * web page the `beforeunload` event is fired and the server is notified that
 * the conversation is abandoned. Server checks for the abandoned conversations and
 * ends all abandoned for longer than 30 seconds (may change). But if the next page
 * contains the chat too, then the event `load` is fired and server is informed the
 * conversation is active again and should not end it.
 *
 * Unfortunately there is not easy way to check that the user closed the tab.
 * See https://trello.com/c/E9dhTqKQ/144#comment-5ddd45e3ee8ad858e78682a2
 */
import { mmReplaceMap } from 'mm-ts-utils';
import { getClientConfig } from '../../modules/_core/utils/app-storage';
import { parseUrl } from '../../modules/_core/utils/app-utils';
var _activeThreads = {};
// beforeunload seems not to be supported on iOS
// https://github.com/jasonzissman/TimeMe.js/issues/28
// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
var IS_IOS = Boolean(navigator.platform) && /iPad|iPhone|iPod/.test(navigator.platform);
var UNLOAD_EVT_NAME = IS_IOS ? 'pagehide' : 'unload';
var _buildUrl = function (threadId, state) {
    if (!threadId) {
        return null;
    }
    var _url = parseUrl();
    // The two required parameters are `threadId: string` and `state: 'active' | 'inactive' | 'alive'`.
    // See https://medialize.github.io/URI.js/uri-template.html
    var uriTpl = getClientConfig().THREAD_HEARTBEAT_URI_TEMPLATE;
    return uriTpl
        ? mmReplaceMap(uriTpl, {
            '{protocol}': _url.protocol,
            '{hostname}': _url.hostname,
            '{port}': _url.port,
            '{threadId}': threadId,
            '{state}': state,
        })
        : null;
};
export var ThreadHeartbeatState;
(function (ThreadHeartbeatState) {
    ThreadHeartbeatState["INACTIVE"] = "inactive";
    ThreadHeartbeatState["ACTIVE"] = "active";
})(ThreadHeartbeatState || (ThreadHeartbeatState = {}));
var noop = Function.prototype;
var _trySendUnloadRequest = function (threadId, state, data) {
    var _url = _buildUrl(threadId, state);
    if (_url && fetch) {
        var headers = {
            'Content-Type': 'application/json; charset=utf-8',
        };
        var body = JSON.stringify(__assign(__assign({}, data), { clientTimestamp: new Date() }));
        // "keepalive" flag is very important here because otherwise
        // the request is canceled after the browser tab is closed
        fetch(_url, { method: 'POST', headers: headers, body: body, keepalive: true });
    }
};
function notifyServerThreadIsInactive(threadId, data) {
    _trySendUnloadRequest(threadId, ThreadHeartbeatState.INACTIVE, data);
}
function notifyServerThreadIsActive(threadId, data) {
    _trySendUnloadRequest(threadId, ThreadHeartbeatState.ACTIVE, data);
}
function createBeforeUnloadNotificator(threadId) {
    if (!threadId) {
        return noop;
    }
    else if (!_activeThreads[threadId]) {
        return (_activeThreads[threadId] = function () { return notifyServerThreadIsInactive(threadId); });
    }
    else {
        return _activeThreads[threadId];
    }
}
function _activate(threadId) {
    // this saves server requests, but is less transparent
    if (!_activeThreads[threadId]) {
        notifyServerThreadIsActive(threadId);
        window.addEventListener(UNLOAD_EVT_NAME, createBeforeUnloadNotificator(threadId));
    }
}
function _inactivate(threadId) {
    notifyServerThreadIsInactive(threadId);
    window.removeEventListener(UNLOAD_EVT_NAME, createBeforeUnloadNotificator(threadId));
    delete _activeThreads[threadId];
}
export var handleThreadHeartbeatOnComponentMount = _activate;
export function handleThreadHeartbeatOnComponentUpdate(threadId, prevThreadId) {
    if (threadId !== prevThreadId && prevThreadId) {
        _inactivate(prevThreadId);
    }
    _activate(threadId);
}
export var handleThreadHeartbeatOnComponentUnmount = _inactivate;
