import { __extends } from "tslib";
import { WsMessage } from 'mm-ts-utils-client';
import { WsClient } from 'mm-ts-utils-client/dist/ws/WsClient';
import * as React from 'react';
import { connect } from 'react-redux';
import { appWscInstance } from '../../../_library/utils/app-ws-client';
import { domainAction } from '../../domain/domain-redux';
import { identitySelector } from '../../identity/identity-redux';
import { apiNormalizeJsonApiResponse } from '../utils/api-helpers';
import { createDebugLogger } from '../utils/app-utils';
var WsChannelClass = /** @class */ (function (_super) {
    __extends(WsChannelClass, _super);
    function WsChannelClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('WsChannel');
        _this.state = {
            isOpen: false,
            wsCid: null,
        };
        // sem core biznis na routovanie incomming messagov
        _this.onMessage = function (data) {
            var dispatch = _this.props.dispatch;
            var m = WsMessage.factory(data);
            if (m.type === WsMessage.TYPE_CONNECTION_ESTABLISHED) {
                _this.setState({ wsCid: _this.wsc.cid });
            }
            // toto je vlastne jadro biznisu -> posielame normalizovany
            // jsonapi domain format, ktory mergujeme do storu
            if (m.type === WsMessage.TYPE_JSONAPI) {
                try {
                    var jsonapi = JSON.parse(m.payload);
                    var normalized = apiNormalizeJsonApiResponse(jsonapi);
                    dispatch(domainAction.mergeModelsPayload(normalized));
                    // UGLY SPECIAL CASE
                    // this._maybeUpdateIdentity(normalized);
                }
                catch (e) {
                    console.error("TYPE_JSONAPI: Unable to parse payload: ".concat(e.toString()), m.payload);
                }
            }
            // podobne ako vyssie, akurat ze bude mergovat (updatovat) iba uz existujuce
            // modeli v store
            if (m.type === WsMessage.TYPE_JSONAPI_UPDATE) {
                try {
                    var jsonapi = JSON.parse(m.payload);
                    var normalized = apiNormalizeJsonApiResponse(jsonapi);
                    dispatch(domainAction.updateDomainModelsPayload(normalized));
                    // UGLY SPECIAL CASE
                    // this._maybeUpdateIdentity(normalized);
                }
                catch (e) {
                    console.error("TYPE_JSONAPI_UPDATE: Unable to parse ws jsonapi message payload: ".concat(e.toString()), m.payload);
                }
            }
            if (m.type === WsMessage.TYPE_JSONAPI_DELETE) {
                try {
                    // note: tu bola urobena zla implementacia... vyzeralo to ako narychlo
                    // hack. Nizsou (spravnou) impl. povodne nebude fungovat. Problem je,
                    // ze netusim ktory use case to pouzival (a ci vobec nejaky)
                    var jsonapi = JSON.parse(m.payload);
                    var normalized_1 = apiNormalizeJsonApiResponse(jsonapi);
                    var deleteInfo = Object.keys(normalized_1).reduce(function (memo, type) {
                        Object.keys(normalized_1[type]).forEach(function (id) {
                            memo.push({ type: type, id: id });
                        });
                        return memo;
                    }, []);
                    dispatch(domainAction.deleteDomainModels(deleteInfo));
                }
                catch (e) {
                    console.error("TYPE_JSONAPI_DELETE: Unable to parse ws jsonapi message payload: ".concat(e.toString()), m.payload);
                }
            }
        };
        /**
         * UGLY special case... Primarny zmysel je is_online flag
         * @param data
         * @private
         */
        // _maybeUpdateIdentity(data) {
        //     const { dispatch, identity } = this.props;
        //     if (data.user && data.user[identity.id]) {
        //         let updated = {
        //             ...identity,
        //             ...pick(data.user[identity.id], Object.keys(identity)),
        //             last_known_heartbeat: null, // DOLEZITE: vid tiez authActions.identity.setIdentity
        //         };
        //         if (!isEqual(updated, identity)) {
        //             dispatch(authActions.identity.setIdentity(updated));
        //         }
        //     }
        // }
        _this.onOpen = function (e) {
            _this._saveWsReadyState();
            _this.joinUserRoom();
        };
        _this.onClose = function (e) {
            _this._saveWsReadyState();
        };
        return _this;
    }
    // constructor(props: WsChannelProps) {
    //     super(props);
    // }
    WsChannelClass.prototype.componentDidMount = function () {
        //
        this.wsc = appWscInstance();
        if (this.wsc) {
            this.wsc.on(WsClient.EVENT_OPEN, this.onOpen);
            this.wsc.on(WsClient.EVENT_CLOSE, this.onClose);
            this.wsc.on(WsClient.EVENT_MESSAGE, this.onMessage);
        }
    };
    WsChannelClass.prototype.componentWillUnmount = function () {
        if (this.wsc) {
            this.wsc.off(WsClient.EVENT_OPEN, this.onOpen);
            this.wsc.off(WsClient.EVENT_CLOSE, this.onClose);
            this.wsc.off(WsClient.EVENT_MESSAGE, this.onMessage);
        }
    };
    WsChannelClass.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (!this.wsc) {
            return;
        }
        // ak dochadza k zmene existujucej identity, tak sa odhlasime z roomy...
        // (technicky nemusime, ale principialne upratujueme po sebe)
        var identity = this.props.identity;
        //
        if (!identity && prevProps.identity) {
            this.wsc.leaveRoom(prevProps.identity.id);
        }
        //
        else if (identity &&
            prevProps.identity &&
            identity.id !== prevProps.identity.id) {
            this.wsc.leaveRoom(prevProps.identity.id);
            this.wsc.joinRoom(identity.id);
        }
    };
    WsChannelClass.prototype.joinUserRoom = function () {
        var identity = this.props.identity;
        if (this.wsc && identity && identity.id) {
            this.wsc.joinRoom(identity.id);
        }
    };
    WsChannelClass.prototype.render = function () {
        this.joinUserRoom();
        return null;
    };
    WsChannelClass.prototype._saveWsReadyState = function () {
        this.setState({ isOpen: this.wsc.isOpen() });
    };
    return WsChannelClass;
}(React.Component));
var mapStateToProps = function (state, ownProps) { return ({
    identity: identitySelector.getIdentity(state),
}); };
var mapDispatchToProps = function (dispatch, ownProps) { return ({
    dispatch: dispatch,
}); };
export var WsChannel = connect(mapStateToProps, mapDispatchToProps)(WsChannelClass);
