export var LOADABLE_COMPONENTS_MAP = {
    Header: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "Header" */
        './modules/_core/components/Header/Header');
    },
    TextMessage: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "TextMessage" */
        './modules/chat/components/Messages/TextMessage');
    },
    CarouselWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "CarouselWidget" */
        './modules/chat/components/CarouselWidget/CarouselWidget');
    },
    GenericFormWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "GenericFormWidget" */
        './modules/chat/components/GenericFormWidget/GenericFormWidget');
    },
    NotificationWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "NotificationWidget" */
        './modules/chat/components/NotificationWidget/NotificationWidget');
    },
    TilesWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "TilesWidget" */
        './modules/chat/components/TilesWidget/TilesWidget');
    },
    AnnouncementWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "AnnouncementWidget" */
        './modules/chat/components/AnnouncementWidget');
    },
    NewConversationWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "NewConversationWidget" */
        './modules/chat/components/NewConversationWidget');
    },
    ChatbotRatingWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "ChatbotRatingWidget" */
        './modules/chat/components/ChatbotRatingWidget/ChatbotRatingWidget');
    },
    CarouselSearchWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "CarouselSearchWidget" */
        './modules/chat/components/CarouselSearchWidget/CarouselSearchWidget');
    },
    NPSRatingWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "NPSRatingWidget" */
        './modules/chat/components/NPSRatingWidget/NPSRatingWidget');
    },
    LikeDislikeRatingWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "LikeDislikeRatingWidget" */
        './modules/chat/components/LikeDislikeRatingWidget/LikeDislikeRatingWidget');
    },
    CheckboxWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "CheckboxWidget" */
        './modules/chat/components/CheckboxWidget/CheckboxWidget');
    },
    CalendarWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "CalendarWidget" */
        './modules/chat/components/CalendarWidget/CalendarWidget');
    },
    GeolocationWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "GeolocationWidget" */
        './modules/chat/components/GeolocationWidget/GeolocationWidget');
    },
    HorizontalStepperWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "HorizontalStepperWidget" */
        './modules/chat/components/HorizontalStepperWidget/HorizontalStepperWidget');
    },
    PaymentScheduleWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "PaymentScheduleWidget" */
        './modules/chat/components/PaymentScheduleWidget/PaymentScheduleWidget');
    },
    PhotoWidget: function () {
        return import(
        /* webpackPrefetch: true, webpackChunkName: "PhotoWidget" */
        './modules/chat/components/PhotoWidget/PhotoWidget');
    },
};
