import { __extends } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import reactStringReplace from 'react-string-replace';
import { Button } from 'reactstrap';
import { config } from '../../../../../_core/config';
import { createDebugLogger } from '../../../../../_core/utils/app-utils';
import { I18nMsgNs } from '../../../../../_core/utils/i18n-helpers';
import './ExternalLink.scss';
var Msg = I18nMsgNs('ExternalLink');
var ExternalLink = /** @class */ (function (_super) {
    __extends(ExternalLink, _super);
    function ExternalLink(props) {
        var _this = _super.call(this, props) || this;
        _this.debug = createDebugLogger('ExternalLink');
        _this.onClick = _this.onClick.bind(_this);
        return _this;
    }
    ExternalLink.prototype.componentDidMount = function () {
        // const { url, redirect } = this.props;
        //
        // if (url && redirect) {
        //     window.open(url, '_blank');
        // }
    };
    ExternalLink.prototype.onClick = function (e) {
        e.preventDefault();
        var url = this.props.url;
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(e, e.currentTarget.value);
            window.open(url, '_blank');
            return;
        }
        console.warn("Unable to submit '".concat(e.currentTarget.value, "'"));
    };
    ExternalLink.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, description = _a.description, url = _a.url, image = _a.image, disabled = _a.disabled;
        var B = config.css.B('-external-link');
        var replacedText;
        // Match URLs
        replacedText = reactStringReplace(description, /__url__/g, function (match, i) { return (React.createElement(Button, { key: match + i, color: "primary", onClick: _this.onClick, value: title, disabled: disabled, className: description === '__url__' ? 'mt-0' : null }, title)); });
        // Match Images
        replacedText = reactStringReplace(replacedText, /(__image__)/g, function (match, i) { return (React.createElement("div", { className: "text-center", key: match + i },
            React.createElement("img", { src: image, alt: "Test" }))); });
        return React.createElement("div", { className: cn(B, disabled && "".concat(B, "--disabled")) }, replacedText);
    };
    ExternalLink.defaultProps = {
        disabled: false,
        redirect: false,
    };
    return ExternalLink;
}(React.Component));
export { ExternalLink };
export default ExternalLink;
