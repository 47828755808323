import { __awaiter, __generator } from "tslib";
import { createDebugLogger } from '../client/src/modules/_core/utils/app-utils';
var debug = createDebugLogger('components-map-externals');
// DOLEZITE: DOPLNAT NIZSIE PRIEBEZNE PRE KAZDY NOVY EXTERNALS...
// do buildu sa prida iba to co bude uvedene v .env v `EXTERNALS`
// prettier-ignore
var externals = {
    'jablotron': function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, import(/* webpackChunkName: "externals-jablotron " */ './jablotron')];
    }); }); }, // for testing
};
debug('Available externals: ' + JSON.stringify(Object.keys(externals)));
// NOTE: zial vyssie musi byt loadovane asynchronne na dva krat, lebo inak by sucastou
// buildu boli vsetky externe mapy... (co mozno nie je kriticke, ale rozhodne skarede)
// teda toto by fungovalo, ale najskor to nechceme:
// import zsdis from './zsdis';
// let externals = { zsdis };
// NIZSIE BY NEMALO BYT TREBA EDITOVAT ///////////////////////////////////////////
var LOADABLE_COMPONENTS_MAP_EXTERNALS = {};
var EXTERNALS = (window['__CLIENT_CONFIG__'] && window['__CLIENT_CONFIG__'].EXTERNALS) || process.env.EXTERNALS;
(function () { return __awaiter(void 0, void 0, void 0, function () {
    var parsed, _loop_1, _i, parsed_1, name_1, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                parsed = JSON.parse(EXTERNALS || '[]');
                if (!Array.isArray(parsed)) {
                    parsed = [parsed];
                }
                parsed = parsed.map(function (v) { return v.trim(); }).filter(function (v) { return !!v; });
                debug('Declared externals: ' + JSON.stringify(parsed));
                _loop_1 = function (name_1) {
                    var result;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, externals[name_1]()];
                            case 1:
                                result = _b.sent();
                                if (result.default) {
                                    debug("Bootstrapping external: ".concat(name_1));
                                    Object.keys(result.default).forEach(function (k) {
                                        debug('Loaded from external: ' + k);
                                        LOADABLE_COMPONENTS_MAP_EXTERNALS[k] = result.default[k];
                                    });
                                }
                                return [2 /*return*/];
                        }
                    });
                };
                _i = 0, parsed_1 = parsed;
                _a.label = 1;
            case 1:
                if (!(_i < parsed_1.length)) return [3 /*break*/, 4];
                name_1 = parsed_1[_i];
                return [5 /*yield**/, _loop_1(name_1)];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                _i++;
                return [3 /*break*/, 1];
            case 4: return [3 /*break*/, 6];
            case 5:
                e_1 = _a.sent();
                console.warn("Invalid value in ENV variable \"EXTERNALS\". Got: ".concat(process.env.EXTERNALS));
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}); })();
export default LOADABLE_COMPONENTS_MAP_EXTERNALS;
