import { __assign, __awaiter, __generator } from "tslib";
import { generateNewThreadId } from '../../../_library/utils/identifiers';
import { api } from '../../_core/utils/api-helpers';
import { pmaMarkFulfilled, pmaMarkRejected, pmaResetExistingMark, } from '../../_core/utils/pma-reducer';
import { domainAction, domainSelector } from '../../domain/domain-redux';
import { chatActionType } from '../chat-redux';
/**
 * @param identity
 * @param threadId
 * @param optimisticData
 */
export var createThread = function (identity, threadId, optimisticData) {
    if (threadId === void 0) { threadId = generateNewThreadId(); }
    if (optimisticData === void 0) { optimisticData = {}; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var id, type, rootState, options, value, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    identity = identity || {};
                    id = threadId;
                    type = chatActionType.FETCH_CREATE_THREAD;
                    rootState = getState();
                    options = {
                        initialData: domainSelector.getInitialData(rootState) || {},
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    dispatch(pmaResetExistingMark(type, id));
                    return [4 /*yield*/, dispatch({
                            type: type,
                            payload: {
                                data: __assign(__assign({}, optimisticData), { id: id }),
                                promise: api().createThread(threadId, identity.api_token, options),
                            },
                        })];
                case 2:
                    value = (_a.sent()).value;
                    dispatch(pmaMarkFulfilled(type, value, id));
                    dispatch(domainAction.mergeModelsPayload(value));
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    dispatch(pmaMarkRejected(type, e_1, threadId));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
