import { __extends } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { TextareaAutosize } from 'react-autosize-textarea/lib/TextareaAutosize';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { config } from '../../../_core/config';
import { getClientConfig } from '../../../_core/utils/app-storage';
import { createDebugLogger } from '../../../_core/utils/app-utils';
import { I18nMsgNs } from '../../../_core/utils/i18n-helpers';
import { domainSelector } from '../../../domain/domain-redux';
import './FreeText.scss';
var KEY_CODE_ENTER = 13;
/** https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode#Constants_for_keyCode_value */
var CONTROL_KEYS = [
    9,
    16,
    17,
    18,
    20,
    37,
    38,
    39,
    40,
    91,
    93, // Opening context menu key.
];
var Msg = I18nMsgNs('FreeText');
var FreeTextClass = /** @class */ (function (_super) {
    __extends(FreeTextClass, _super);
    function FreeTextClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('FreeText');
        _this.state = {
            value: '',
        };
        _this._handleChange = function (e) {
            var MAX_INPUT_LENGTH = getClientConfig().MAX_INPUT_LENGTH;
            var value = e.currentTarget.value;
            if (MAX_INPUT_LENGTH)
                value = value.substring(0, MAX_INPUT_LENGTH);
            _this.setState({
                value: value,
            });
        };
        _this._handleKeyDown = function (e) {
            if (e.keyCode === KEY_CODE_ENTER && e.shiftKey === false) {
                e.preventDefault();
            }
        };
        _this._handleKeyUp = function (e) {
            if (e.keyCode === KEY_CODE_ENTER && e.shiftKey === false) {
                e.preventDefault();
                return _this.onSubmit();
            }
            // iba ak realne piseme, nie control keys...
            if (!CONTROL_KEYS.includes(e.keyCode)) {
                // always notify immediately - let's move the throttling/debouncing
                // responsibility to the consumer (so we're open to any implementation)
                _this._isTypingNotify();
                _this._stoppedTypingTimer && clearTimeout(_this._stoppedTypingTimer);
                _this._stoppedTypingTimer = setTimeout(_this._stoppedTypingNotify.bind(_this), FreeTextClass.STOPPED_TYPING_TIMEOUT);
            }
        };
        _this._handleSubmit = function (e) {
            e.preventDefault();
            _this.onSubmit();
        };
        return _this;
    }
    FreeTextClass.prototype.componentWillUnmount = function () {
        // delete window.__FREE_TEXT_INPUT_SET_VALUE;
        this._stoppedTypingTimer && clearTimeout(this._stoppedTypingTimer);
    };
    FreeTextClass.prototype._stoppedTypingNotify = function () {
        var onIsTyping = this.props.onIsTyping;
        onIsTyping && onIsTyping(false, this.state.value);
    };
    FreeTextClass.prototype._isTypingNotify = function () {
        var onIsTyping = this.props.onIsTyping;
        onIsTyping && onIsTyping(true, this.state.value);
    };
    FreeTextClass.prototype.onSubmit = function () {
        var value = this.state.value;
        if (value.trim() && typeof this.props.submitMessage === 'function') {
            this.setState({ value: '' });
            this.props.submitMessage(value);
        }
    };
    FreeTextClass.prototype.render = function () {
        var _a;
        var _b = this.props, textareaMode = _b.textareaMode, meta = _b.meta, hidden = _b.hidden, intl = _b.intl;
        var disabled = ((_a = meta === null || meta === void 0 ? void 0 : meta.named_entities) === null || _a === void 0 ? void 0 : _a.isChatInputHidden) || this.props.disabled;
        if (hidden) {
            return null;
        }
        var i18n = function (id, values, defaultMessage) {
            return intl.formatMessage({ id: "FreeText.".concat(id), defaultMessage: defaultMessage }, values);
        };
        var B = config.css.B('-freetext');
        return (React.createElement("form", { onSubmit: this._handleSubmit, className: cn(B, this.state.value && "".concat(B, "__dirty")) },
            React.createElement("div", { className: cn("".concat(B, "__input")) }, textareaMode ? this._renderTextarea(i18n) : this._renderInput(i18n)),
            React.createElement(Button, { disabled: disabled || !this.state.value.trim(), type: "submit", color: "", className: "submit-button" },
                React.createElement(Msg, { id: "submit" }))));
    };
    FreeTextClass.prototype._renderInput = function (tns) {
        var _a;
        var _b = this.props, placeholder = _b.placeholder, meta = _b.meta;
        var disabled = ((_a = meta === null || meta === void 0 ? void 0 : meta.named_entities) === null || _a === void 0 ? void 0 : _a.isChatInputHidden) || this.props.disabled;
        // prettier-ignore
        return (React.createElement("input", { autoComplete: "nope", name: "chat-input-text", type: "text", value: this.state.value, onChange: this._handleChange, placeholder: placeholder || tns(disabled ? 'placeholderDisabled' : 'placeholder'), autoFocus: true, onKeyUp: this._handleKeyUp, onKeyDown: this._handleKeyDown, disabled: disabled }));
    };
    FreeTextClass.prototype._renderTextarea = function (tns) {
        var _a;
        var _b = this.props, placeholder = _b.placeholder, meta = _b.meta;
        var disabled = ((_a = meta === null || meta === void 0 ? void 0 : meta.named_entities) === null || _a === void 0 ? void 0 : _a.isChatInputHidden) || this.props.disabled;
        // prettier-ignore
        return (React.createElement(TextareaAutosize, { autoComplete: "nope", name: "chat-input-text", disabled: disabled, value: this.state.value, onChange: this._handleChange, placeholder: placeholder || tns(disabled ? 'placeholderDisabled' : 'placeholder'), autoFocus: true, rows: 1, maxRows: 4, onKeyUp: this._handleKeyUp, onKeyDown: this._handleKeyDown }));
    };
    FreeTextClass.defaultProps = {
        textareaMode: true,
    };
    // configurable via externals
    FreeTextClass.STOPPED_TYPING_TIMEOUT = 10000;
    return FreeTextClass;
}(React.Component));
export { FreeTextClass };
var mapStateToProps = function (state) {
    var uiState = domainSelector.getUIState(state);
    return {
        disabled: uiState.chat_input_disabled,
        hidden: uiState.chat_input_hidden,
    };
};
export var FreeText = connect(mapStateToProps)(injectIntl(FreeTextClass));
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default FreeText;
