import { __extends } from "tslib";
import { BaseModel } from 'mm-ts-utils';
import { AGENT_USER_ID, ENTITY_TYPE_CHAT_MESSAGE, GOODBOT_USER_ID } from '../../consts';
export var ChatMessageType;
(function (ChatMessageType) {
    ChatMessageType["TEXT"] = "text";
    ChatMessageType["TRANSIENT"] = "transient";
    /** @deprecated old type, not used anymore */
    ChatMessageType["TRANSIENT_IS_TYPING"] = "transient_is_typing";
    ChatMessageType["DEFAULT"] = "default";
    ChatMessageType["MULTIPLE_CHOICES"] = "multiple_choices";
    ChatMessageType["NO_RESULTS"] = "no_results";
    ChatMessageType["INTENT_NOT_RECOGNIZED"] = "unrecognized_intent";
    ChatMessageType["INTENT_NONE"] = "intent_none";
    ChatMessageType["WIDGET"] = "widget";
    ChatMessageType["NOTIFICATION"] = "notification";
    ChatMessageType["END"] = "end";
    ChatMessageType["HIDDEN"] = "hidden";
})(ChatMessageType || (ChatMessageType = {}));
var TRANSIENT_TYPES = [
    ChatMessageType.TRANSIENT_IS_TYPING,
    ChatMessageType.TRANSIENT,
    ChatMessageType.NOTIFICATION,
];
var BaseChatMessage = /** @class */ (function (_super) {
    __extends(BaseChatMessage, _super);
    function BaseChatMessage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.entityType = ENTITY_TYPE_CHAT_MESSAGE;
        return _this;
    }
    Object.defineProperty(BaseChatMessage.prototype, "user_id", {
        //
        get: function () {
            return this._get('user_id');
        },
        set: function (v) {
            this._set('user_id', v);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "thread_id", {
        get: function () {
            return this._get('thread_id');
        },
        set: function (v) {
            this._set('thread_id', v);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "type", {
        get: function () {
            return this._get('type');
        },
        set: function (v) {
            this._set('type', v);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "body", {
        get: function () {
            return this._get('body');
        },
        set: function (v) {
            this._set('body', v);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "meta", {
        get: function () {
            return this._get('meta', {});
        },
        set: function (v) {
            this._set('meta', this._maybeUnserialize(v));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "created", {
        get: function () {
            return this._get('created');
        },
        set: function (v) {
            this._set('created', v ? new Date(v) : null);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "is_bot", {
        get: function () {
            return BaseChatMessage.getBotUserIds().indexOf(this.user_id) !== -1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "is_agent", {
        get: function () {
            return BaseChatMessage.getAgentIds().indexOf(this.user_id) !== -1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "_defaults", {
        get: function () {
            return BaseChatMessage.defaults();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "authorType", {
        get: function () {
            if (typeof BaseChatMessage.authorTypeResolver === 'function') {
                return BaseChatMessage.authorTypeResolver(this);
            }
            return this.is_bot
                ? BaseChatMessage.AUTHOR_TYPE_BOT
                : this.is_agent
                    ? BaseChatMessage.AUTHOR_TYPE_AGENT
                    : BaseChatMessage.AUTHOR_TYPE_USER;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseChatMessage.prototype, "isTransientLike", {
        get: function () {
            return TRANSIENT_TYPES.includes(this.type);
        },
        enumerable: false,
        configurable: true
    });
    BaseChatMessage.defaults = function () {
        return Object.assign({}, BaseModel.defaults(), {
            user_id: null,
            thread_id: null,
            type: ChatMessageType.DEFAULT,
            body: null,
            meta: null,
            created: new Date(),
            updated: null,
        });
    };
    BaseChatMessage.AUTHOR_TYPE_USER = 'user';
    BaseChatMessage.AUTHOR_TYPE_BOT = 'bot';
    BaseChatMessage.AUTHOR_TYPE_AGENT = 'agent';
    // quick-n-dirty, to be extended via externals if needed
    BaseChatMessage.getBotUserIds = function () { return [GOODBOT_USER_ID]; };
    // quick-n-dirty, to be extended via externals if needed
    BaseChatMessage.getAgentIds = function () { return [AGENT_USER_ID]; };
    return BaseChatMessage;
}(BaseModel));
export { BaseChatMessage };
