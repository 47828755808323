import produce from 'immer';
import isPlainObject from 'lodash-es/isPlainObject';
/**
 * PMA = Promise Middleware Aware
 */
var EMPTY_ID = '__EMPTY_ID__';
export var PMA_PENDING = 'PENDING';
export var PMA_FULFILLED = 'FULFILLED';
export var PMA_REJECTED = 'REJECTED';
export var PMA_NONE = 'NONE';
//
var pmaTypePending = function (type) {
    return "".concat(type, "_").concat(PMA_PENDING);
};
var pmaTypeFulfilled = function (type) {
    return "".concat(type, "_").concat(PMA_FULFILLED);
};
var pmaTypeRejected = function (type) {
    return "".concat(type, "_").concat(PMA_REJECTED);
};
//
var pmaIsPendingSelector = function (state) {
    return function (id) {
        var _a;
        id = pmaNormalizeId(id || EMPTY_ID);
        return ((_a = state[id]) === null || _a === void 0 ? void 0 : _a.type) === PMA_PENDING;
    };
};
var pmaIsFulfilledSelector = function (state) {
    return function (id) {
        var _a;
        id = pmaNormalizeId(id || EMPTY_ID);
        return ((_a = state[id]) === null || _a === void 0 ? void 0 : _a.type) === PMA_FULFILLED;
    };
};
var pmaIsRejectedSelector = function (state) {
    return function (id) {
        var _a;
        id = pmaNormalizeId(id || EMPTY_ID);
        return ((_a = state[id]) === null || _a === void 0 ? void 0 : _a.type) === PMA_REJECTED;
    };
};
/**
 * The word "status" is used instead of state (Finite State Machine term)
 * because the word "state" is already used by Redux in int store.
 */
var pmaStatusSelector = function (state) {
    return function (id) {
        if (pmaIsPendingSelector(state)(id)) {
            return PMA_PENDING;
        }
        if (pmaIsFulfilledSelector(state)(id)) {
            return PMA_FULFILLED;
        }
        if (pmaIsRejectedSelector(state)(id)) {
            return PMA_REJECTED;
        }
        return PMA_NONE;
    };
};
var pmaLastErrorSelector = function (state) {
    return function (id) {
        id = pmaNormalizeId(id || EMPTY_ID);
        var log = state[id];
        if ((log === null || log === void 0 ? void 0 : log.type) === PMA_REJECTED) {
            return log.error;
        }
        return null;
    };
};
// toto trosku hackujem, lebo si chcem nejak normalizovane znacit podla custom
// idecka co bolo fullfilnute... je to ok, akurat toto musim volat manualne...
var pmaTypeFulfilledMark = function (type) { return "".concat(type, "_").concat(PMA_FULFILLED, "_MARK"); };
var pmaTypeRejectedMark = function (type) { return "".concat(type, "_").concat(PMA_REJECTED, "_MARK"); };
var pmaTypeMarkReset = function (type) { return "".concat(type, "_MARK_RESET"); };
export var pmaMarkFulfilled = function (type, payload, id) { return ({
    type: pmaTypeFulfilledMark(type),
    payload: { id: id, payload: payload },
}); };
export var pmaResetExistingMark = function (type, id) { return ({
    type: pmaTypeMarkReset(type),
    payload: { id: id },
}); };
export var pmaMarkRejected = function (type, error, threadId) { return ({
    type: pmaTypeRejectedMark(type),
    payload: { id: threadId, threadId: threadId, error: error },
}); };
var pmaCreateInitialState = function () { return ({}); };
/**
 * @param value
 */
var pmaNormalizeId = function (value) {
    if (Array.isArray(value)) {
        // note: tu robit sort moze ale NEMUSI byt ziaduce!
        return value.join('-');
    }
    if (isPlainObject(value)) {
        return Object.keys(value)
            .sort()
            .reduce(function (memo, k) {
            memo.push("".concat(k, ":").concat(value[k])); // memo.push(value[k]);
            return memo;
        }, [])
            .join('-');
    }
    // all others (strings, numbers, ...) return without change...
    return value;
};
/**
 * @param actionType
 */
export var pmaCreateReducer = function (actionType) {
    return produce(function (state, action) {
        var now = new Date();
        var getId = function () {
            var id = (action.payload || {}).id;
            return pmaNormalizeId(id || EMPTY_ID); // je legitimne, ze id pri niektorych calloch neexistuje
        };
        switch (action.type) {
            case pmaTypePending(actionType):
                state[getId()] = { type: PMA_PENDING, timestamp: now };
                break;
            case pmaTypeFulfilled(actionType):
            case pmaTypeFulfilledMark(actionType):
                state[getId()] = {
                    type: PMA_FULFILLED,
                    timestamp: now,
                    payload: action.payload.payload,
                };
                break;
            case pmaTypeRejected(actionType):
            case pmaTypeRejectedMark(actionType):
                state[getId()] = {
                    type: PMA_REJECTED,
                    timestamp: now,
                    error: action.payload.error,
                };
                break;
            case pmaTypeMarkReset(actionType):
                delete state[getId()];
                break;
        }
    }, pmaCreateInitialState());
};
/**
 * @param state
 */
export var pmaCreateSelector = function (state) { return ({
    isPending: pmaIsPendingSelector(state),
    isFulfilled: pmaIsFulfilledSelector(state),
    isRejected: pmaIsRejectedSelector(state),
    status: pmaStatusSelector(state),
    lastError: pmaLastErrorSelector(state),
}); };
