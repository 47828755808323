import omit from 'lodash-es/omit';
var getLastMessage = function (chatThread) {
    var normalizedChatThread = omit(chatThread, ['__EMPTY_ID__']);
    // Pick the last saved message
    var lastSavedMessage = normalizedChatThread[Object.keys(normalizedChatThread)[0]];
    for (var _i = 0, _a = Object.keys(normalizedChatThread); _i < _a.length; _i++) {
        var key = _a[_i];
        var chatThreadMessage = normalizedChatThread[key];
        if (chatThreadMessage.timestamp > lastSavedMessage.timestamp) {
            lastSavedMessage = chatThreadMessage;
        }
    }
    return lastSavedMessage;
};
export var getMeta = function (state) {
    var _a;
    var pmaSavedLastMessage = Object.keys(state.chat.pmaSaveMessage).length > 0 &&
        getLastMessage(state.chat.pmaSaveMessage);
    var pmaCreatedLastMessage = Object.keys(state.chat.pmaCreateThread).length > 0 &&
        getLastMessage(state.chat.pmaCreateThread);
    var finalLastMessage = (pmaSavedLastMessage === null || pmaSavedLastMessage === void 0 ? void 0 : pmaSavedLastMessage.timestamp) > (pmaCreatedLastMessage === null || pmaCreatedLastMessage === void 0 ? void 0 : pmaCreatedLastMessage.timestamp)
        ? pmaSavedLastMessage
        : pmaCreatedLastMessage;
    return ((_a = finalLastMessage === null || finalLastMessage === void 0 ? void 0 : finalLastMessage.payload) === null || _a === void 0 ? void 0 : _a.__meta__) || {};
};
