/** Namespaces for our messages inspired by https://socket.io/docs/v4/rooms/ */
export var MESSAGES_ROOM_NAME = 'BUBBLE';
export var BUBBLE_MSG_TARGET_ORIGIN = '*';
export var BubbleMsg;
(function (BubbleMsg) {
    /** Sent by iframe window informing parent window the app is initialized. */
    BubbleMsg["HELLO"] = "HELLO";
    /** Sent by parent window informing iframe window the init msg was received. */
    BubbleMsg["ECHO"] = "ECHO";
    BubbleMsg["INITIAL_DATA"] = "INITIAL_DATA";
    BubbleMsg["IS_CHAT_IN_PROGRESS"] = "GET_IS_CHAT_IN_PROGRESS";
    BubbleMsg["GET_STATE_RESPONSE"] = "GET_STATE_RESPONSE";
    BubbleMsg["UI_STATE"] = "UI_STATE";
    BubbleMsg["COMMAND"] = "COMMAND";
    /**
     * Sent by builder app to change logic in the bubble dynamically.
     * See https://github.com/Born-Digital-AI/builder/blob/332e45d6/src/components/ChatBotBubble.js#L11
     */
    BubbleMsg["NEW_CONFIG_HASH"] = "BUBBLE_MSG_TYPE_NEW_CONFIG_HASH";
    BubbleMsg["BUILDER_SETTINGS"] = "BUILDER_SETTINGS";
    BubbleMsg["DATA_LAYER_PUSH"] = "DATA_LAYER_PUSH";
})(BubbleMsg || (BubbleMsg = {}));
/**
 * Not sure about the ideology but it seems commands are messages
 * sent by iframe to the parent window.
 */
export var BubbleCommand;
(function (BubbleCommand) {
    BubbleCommand["OPEN"] = "OPEN";
    BubbleCommand["CLOSE"] = "CLOSE";
    BubbleCommand["TOGGLE"] = "TOGGLE";
    BubbleCommand["SHOW"] = "SHOW";
    BubbleCommand["HIDE"] = "HIDE";
    BubbleCommand["SET_TEXT"] = "SET_TEXT";
    BubbleCommand["OPEN_FULLSCREEN_ON"] = "OPEN_FULLSCREEN_ON";
    BubbleCommand["OPEN_FULLSCREEN_OFF"] = "OPEN_FULLSCREEN_OFF";
    BubbleCommand["OPEN_FULLSCREEN_TOGGLE"] = "OPEN_FULLSCREEN_TOGGLE";
    BubbleCommand["GET_STATE"] = "GET_STATE";
    BubbleCommand["REMOVE_CID"] = "REMOVE_CID";
})(BubbleCommand || (BubbleCommand = {}));
