import merge from 'lodash-es/merge';
var ENV = process.env.NODE_ENV || 'production';
/**
 *
 */
var production = {
    appCode: 'gaid',
    css: {
        // "B" from "BEM"
        B: function (name) { return "gaid".concat(name); },
    },
    ws: {
        url: "{{WS_PROTOCOL}}//{{HOST}}/ws/",
    },
};
var development = merge({}, production, {
//
});
//
var environments = { development: development, production: production };
export var config = environments[ENV];
