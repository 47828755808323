import { DOMAIN_TYPE_CHAT_MESSAGE } from '../../../consts';
export var getThreadMessage = function (state) {
    return function (threadId, messageId) {
        var message = (state.domain[DOMAIN_TYPE_CHAT_MESSAGE] || {})[messageId];
        if (message && message.thread_id === threadId) {
            return message;
        }
        return null;
    };
};
