import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { usePrevious } from '../../../../../../_shared/library/use-previous';
import { DOMAIN_TYPE_CLIENT_NOTIFICATION, NOTIFICATION_ID__THREAD_IS_TYPING, } from '../../../../consts';
import { config } from '../../../_core/config';
import { domainAction, domainSelector } from '../../../domain/domain-redux';
import { User } from '../../../user/User';
import './TypingUsers.scss';
// implementation hack
var _ANY_USER_ID_ = '__any__';
// "since" delta to consider is_typing notification as relevant
var TTL_THRESHOLD = 10000;
export function TypingUsers(_a) {
    var threadId = _a.threadId, threadLastUpdated = _a.threadLastUpdated, scrollToBottom = _a.scrollToBottom, parentContainer = _a.parentContainer, _b = _a.iconOnly, iconOnly = _b === void 0 ? true : _b;
    var dispatch = useDispatch();
    var typingUsers = useTypingUsers(threadId, threadLastUpdated);
    var names = getCurrentlyTypingUsers(typingUsers, threadLastUpdated);
    var currUsersCount = names.length;
    var prevUsersCount = usePrevious(currUsersCount);
    var $el = React.createRef();
    useEffect(function () {
        // domain cleanup, so TypingUsers will not show up on page refresh
        if (prevUsersCount && !currUsersCount) {
            dispatch(domainAction.deleteModelByEntityTypeAndId(DOMAIN_TYPE_CLIENT_NOTIFICATION, NOTIFICATION_ID__THREAD_IS_TYPING));
        }
    }, [prevUsersCount, currUsersCount]);
    useEffect(function () {
        return _maybeScrollToBottom(prevUsersCount, currUsersCount, scrollToBottom, parentContainer.current, $el.current);
    }, [prevUsersCount, currUsersCount, parentContainer.current, $el.current]);
    if (currUsersCount === 0) {
        return null;
    }
    var B = config.css.B('-typing-users');
    if (iconOnly) {
        return (React.createElement("div", { className: "".concat(B, " ").concat(B, "--bounce"), ref: $el },
            React.createElement("div", { className: "bounce1" }),
            React.createElement("div", { className: "bounce2" }),
            React.createElement("div", { className: "bounce3" })));
    }
    else {
        return (React.createElement("div", { className: "".concat(B, " ").concat(B, "--users"), ref: $el },
            React.createElement("small", null, names.join(', '))));
    }
}
function useTypingUsers(threadId, threadLastUpdated) {
    var _a, _b;
    var getState = useStore().getState;
    var n = useSelector(domainSelector.getClientNotification(NOTIFICATION_ID__THREAD_IS_TYPING));
    if (!n) {
        return {};
    }
    var typingUsers = {};
    // array of names, ids, or just boolean flag
    var nUserId = (_a = n.payload) === null || _a === void 0 ? void 0 : _a.user_id;
    var nThreadId = (_b = n.payload) === null || _b === void 0 ? void 0 : _b.thread_id;
    var nCreated = new Date(n.created).valueOf();
    if (
    // ak bolo vobec nieco poslane a notifikacia sa tyka aktualneho vlakna
    nUserId &&
        nThreadId === threadId &&
        // a sucasne notifikacia je novsia ako posledny update vlakna (toto bude
        // zmysluplne fungovat len za predpokladu, ze db casy a client su v syncu...)
        nCreated >= threadLastUpdated) {
        // ak sme dostali flag only... tak si podhodime "any" usera...
        if (typeof nUserId === 'boolean') {
            typingUsers[_ANY_USER_ID_] = Date.now();
        }
        else {
            // vzdy normalizujeme na pole ideciek
            nUserId = Array.isArray(nUserId) ? nUserId : [nUserId];
            typingUsers = nUserId.reduce(function (memo, _uid) {
                if (_uid) {
                    var typingUserData = domainSelector.getUser(getState())(_uid);
                    var typingUserName = typingUserData
                        ? new User(typingUserData).name || _uid
                        : _uid;
                    memo[typingUserName] = Date.now();
                }
                return memo;
            }, {});
        }
    }
    return typingUsers;
}
function getCurrentlyTypingUsers(typingUsers, threadLastUpdated) {
    var now = Date.now();
    return Object.entries(typingUsers)
        .filter(function (_a) {
        var millis = _a[1];
        return now - millis <= TTL_THRESHOLD && millis >= threadLastUpdated;
    })
        .map(function (_a) {
        var u = _a[0];
        return u;
    });
}
function _maybeScrollToBottom(prevUsersCount, currUsersCount, scrollToBottom, $parent, $el) {
    // scrollovat dole pri isTyping chceme pomerne defenzivne, teda: iba ak sa objavujeme nanovo...
    if (currUsersCount && !prevUsersCount && $parent && $el) {
        var elBox = $el.getBoundingClientRect();
        var parentBox = $parent.getBoundingClientRect();
        // a sucasne: iba ak je element ciastocne viditelny, tak ho scrollneme dole
        // inak sme noop
        // poznamka: nizsie je viac pokus/omyl ako teoreticka uvaha...
        // prettier-ignore
        var shouldScroll = ($parent.scrollHeight - $parent.scrollTop - parentBox.height - elBox.height)
            <= elBox.height;
        if (shouldScroll && scrollToBottom) {
            scrollToBottom();
        }
    }
}
