var _this = this;
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { DOMAIN_TYPE_CHAT_MESSAGE, DOMAIN_TYPE_CHAT_THREAD, DOMAIN_TYPE_USER, } from '../../../../consts';
import { domainAction, domainSelector } from '../../../domain/domain-redux';
import { dispatchUiState } from '../../../domain/ui-state';
import { config } from '../../config';
import { url } from '../../url';
import { getClientConfig } from '../../utils/app-storage';
import { createDebugLogger } from '../../utils/app-utils';
import { I18nMsgNs } from '../../utils/i18n-helpers';
import { DefaultLayout } from '../Layout/DefaultLayout';
import { LayoutSegment } from '../Layout/LayoutSegment';
import './PageHome.scss';
var _isFn = function (v) { return typeof v === 'function'; };
var debug = createDebugLogger('PageHome');
var Msg = I18nMsgNs('PageHome');
export var PageHome = function () {
    var dispatch = useDispatch();
    var initialData = useSelector(domainSelector.getInitialData);
    var history = useHistory();
    var thisComponent = _this;
    PageHome.onStartNewClick = function () {
        history.push(url.CHAT());
    };
    useEffect(function () {
        // reset to default values, typically PROJECT_TITLE
        dispatchUiState(dispatch, { header: null });
        // EXPERIMENTAL: garbage collect redux-state on each page home visit...
        // CORE MOTIVATION: keep the state as minimal as possible, due to new persistence
        // feature (JSON.stringify/parse volume optimization)
        // TODO: if no edge cases found, move under "chatAction.garbageCollect" or similar
        dispatch(domainAction.resetDomainModelEnityType(DOMAIN_TYPE_CHAT_MESSAGE));
        dispatch(domainAction.resetDomainModelEnityType(DOMAIN_TYPE_CHAT_THREAD));
        // might cause issues if we'd store identity as a user domain model
        dispatch(domainAction.resetDomainModelEnityType(DOMAIN_TYPE_USER));
    }, []);
    useEffect(function () {
        if (_isFn(PageHome.onInitialDataHandler) && initialData) {
            debug('Going to handle initial data', initialData);
            PageHome.onInitialDataHandler(thisComponent, initialData);
        }
    }, [initialData]);
    // completely external render?
    if (_isFn(PageHome.renderer)) {
        return PageHome.renderer(_this, {});
    }
    return (React.createElement(DefaultLayout, null,
        React.createElement(LayoutSegment, { label: "main" },
            React.createElement("div", { className: config.css.B('-page-home') }, _isFn(PageHome.rendererMain) ? (PageHome.rendererMain(thisComponent)) : (React.createElement(Button, { color: "primary", size: "lg", onClick: PageHome.onStartNewClick },
                React.createElement(Msg, { id: "startNew" })))))));
};
PageHome.getInitialRoute = function () { return (getClientConfig().INITIAL_ROUTE || url.HOME()).trim(); };
PageHome.onStartNewClick = function () { return undefined; };
PageHome.resetStatics = function () {
    PageHome.renderer = undefined;
    PageHome.rendererMain = undefined;
    PageHome.onInitialDataHandler = undefined;
    PageHome.getInitialRoute = function () {
        return (getClientConfig().INITIAL_ROUTE || url.HOME()).trim();
    };
};
