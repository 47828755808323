import { __extends } from "tslib";
import { BaseModel, mmGetRandomInt } from 'mm-ts-utils';
import { ENTITY_TYPE_USER } from '../../consts';
// const withToJSON = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
// ...hm, nedari sa mi to...
// };
var BaseUser = /** @class */ (function (_super) {
    __extends(BaseUser, _super);
    function BaseUser() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.entityType = ENTITY_TYPE_USER;
        return _this;
    }
    Object.defineProperty(BaseUser.prototype, "custom_id", {
        // toto presuvam na server only
        get: function () {
            if (!this._data.custom_id) {
                this._data.custom_id = 'user-' + mmGetRandomInt(1001, 9999);
            }
            return this._data.custom_id;
        },
        set: function (v) {
            this._set('custom_id', v);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "email", {
        get: function () {
            return this._get('email');
        },
        set: function (v) {
            this._set('email', v);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "first_name", {
        get: function () {
            return this._get('first_name');
        },
        set: function (v) {
            this._set('first_name', v);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "last_name", {
        get: function () {
            return this._get('last_name');
        },
        set: function (v) {
            this._set('last_name', v);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "is_guest", {
        get: function () {
            return this._get('is_guest');
        },
        set: function (v) {
            this._set('is_guest', v ? 1 : 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "is_employee", {
        get: function () {
            return this._get('is_employee');
        },
        set: function (v) {
            this._set('is_employee', v ? 1 : 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "is_administrator", {
        get: function () {
            return this._get('is_administrator');
        },
        set: function (v) {
            this._set('is_administrator', v ? 1 : 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "is_owner", {
        get: function () {
            return this._get('is_owner');
        },
        set: function (v) {
            this._set('is_owner', v ? 1 : 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "last_known_heartbeat", {
        get: function () {
            return this._get('last_known_heartbeat');
        },
        set: function (v) {
            this._set('last_known_heartbeat', v ? new Date(v) : null);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "is_online", {
        get: function () {
            return this._get('is_online');
        },
        set: function (v) {
            this._set('is_online', v ? 1 : 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "settings", {
        get: function () {
            // NOTE: tu umyselne vraciame default {}
            return this._get('settings', {}, false);
        },
        set: function (v) {
            this._set('settings', this._maybeUnserialize(v));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "meta", {
        get: function () {
            // NOTE: tu umyselne vraciame default {}
            return this._get('meta', {});
        },
        set: function (v) {
            this._set('meta', this._maybeUnserialize(v));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "name", {
        get: function () {
            var name = (this._get('first_name') || '') + (this._get('last_name') || '');
            return name.replace(/\s\s+/g, ' ').trim();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "avatar", {
        get: function () {
            return (this.settings || {}).avatar;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseUser.prototype, "_defaults", {
        get: function () {
            return BaseUser.defaults();
        },
        enumerable: false,
        configurable: true
    });
    BaseUser.prototype.initials = function () {
        return ((this._get('first_name') || '').charAt(0) +
            (this._get('last_name') || '').charAt(0)).toUpperCase();
    };
    BaseUser.defaults = function () {
        return Object.assign({}, BaseModel.defaults(), {
            custom_id: null,
            email: null,
            first_name: null,
            last_name: null,
            settings: {},
            meta: {},
            is_guest: 1,
            is_employee: 0,
            is_administrator: 0,
            is_owner: 0,
            is_online: 0,
            last_known_heartbeat: new Date(),
        });
    };
    return BaseUser;
}(BaseModel));
export { BaseUser };
