import { appWscInstance } from './app-ws-client';
export var wsJoinRoom = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var wsc = appWscInstance();
    wsc && wsc.joinRoom.apply(wsc, args);
};
export var wsLeaveRoom = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var wsc = appWscInstance();
    wsc && wsc.leaveRoom.apply(wsc, args);
};
