import { __assign } from "tslib";
var COLORS_FROM_BUILDER = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'link',
];
/**
 * @param inputStr
 * @private
 */
var _parseColorAndOrClassnames = function (inputStr) {
    // idk if this is needed for other purposes
    var colorWhitelist = [
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'link',
    ];
    var customClassNames = [];
    var color = 'primary';
    var label = inputStr;
    // supported forms:
    //      color|label
    //      classname|label
    //      color,classname|label
    //      classname1,classname2|label
    //      color,classname1,classname2|label
    //      Added: (show|hidden)|Color|label
    var parts = inputStr
        .split('|')
        .map(function (v) { return v.trim(); })
        .filter(function (v) { return !!v; });
    // intentionally strict, allow exactly one pipe
    if (parts.length === 2) {
        label = parts[1];
        parts[0]
            .split(',')
            .map(function (v) { return v.trim(); })
            .filter(function (v) { return !!v; })
            .forEach(function (v) {
            if (colorWhitelist.indexOf(v) !== -1) {
                color = v; // later wins
            }
            else {
                customClassNames.push(v);
            }
        });
    }
    else if (parts.length === 3) {
        label = parts[2];
        color = parts[1];
        customClassNames.push(parts[0]);
    }
    return {
        label: label,
        color: color,
        customClassName: customClassNames.length ? customClassNames.join(' ') : null,
    };
};
export var checkHidden = function (inputs) {
    for (var _i = 0, inputs_1 = inputs; _i < inputs_1.length; _i++) {
        var input = inputs_1[_i];
        if (typeof input === 'string') {
            var parts = input.split('|');
            if (parts[0] === 'hidden')
                return true;
        }
    }
    return false;
};
/**
 * @param input
 */
export var parseMarkdownOption = function (input) {
    if (typeof input === 'string') {
        input = { label: input };
    }
    input = input;
    var out = {
        label: null,
        value: null,
        img: null,
        color: null,
        customClassName: null,
    };
    //
    if (input.theme) {
        // ugly BC hack input for parser fn
        out = __assign(__assign({}, out), _parseColorAndOrClassnames(input.theme + '|dummy'));
        out.label = input.label || input.utterance;
    }
    else {
        out = __assign(__assign({}, out), _parseColorAndOrClassnames(input.label || input.utterance));
    }
    // finalize output
    out.value = input.utterance || out.label;
    out.img = input.img || null;
    // update: target_node was removed from output
    // target_node has priority
    // if (input.target_node) {
    //     out.value = '@' + input.target_node;
    // }
    return out;
};
