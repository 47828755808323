import * as React from 'react';
import PageChatLayout from './components/Layout/PageChatLayout';
import { PageHome } from './components/PageHome/PageHome';
import PageMessage from './components/PageMessage/PageMessage';
import { url } from './url';
export var isRouteAuthOnly = function (route) {
    return route.routeMeta && route.routeMeta.authOnly;
};
export var isRouteNotAuthOnly = function (route) {
    return route.routeMeta && route.routeMeta.notAuthOnly;
};
// const loading = () => <div>Loading...</div>;
var loading = function (props) {
    if (props.error) {
        return React.createElement("div", null, props.error.toString());
    }
    else if (props.timedOut) {
        return React.createElement("div", null, "Still loading...");
    }
    else if (props.pastDelay) {
        return React.createElement("div", null, "Loading...");
    }
    // Sometimes components load really quickly (<200ms) and the loading screen
    // only quickly flashes on the screen.
    // A number of user studies have proven that this causes users to perceive
    // things taking longer than they really have. If you don't show anything,
    // users perceive it as being faster.
    else {
        // null nemoze byt, lebo page-transition erroruje potom
        return React.createElement("div", null);
    }
};
// top level routes only config
export var routes = [
    {
        label: 'Home',
        path: url.HOME(),
        exact: true,
        component: PageHome,
    },
    {
        label: 'PageChat',
        path: url.CHAT(':threadId?'),
        exact: true,
        component: PageChatLayout,
    },
    {
        label: 'PageMessage',
        path: url.MESSAGE(':threadId?', ':messageId?'),
        exact: true,
        // UFF... pri asynchronnom loadovani PageMessage dostavam neskor (pri renderi
        // message cez loadable) Cyclic dependency error... comu rozumiem, ale neviem
        // preco pri PageChat tento problem nie je...
        component: PageMessage,
        // component: Loadable({
        //     loader: getLoadableComponentLoader('PageMessage'),
        //     loading,
        // }),
    },
    {
        label: 'PageNotAllowed',
        path: url.NOT_ALLOWED(),
        exact: true,
        component: function () { return React.createElement("div", null, "Not allowed"); },
    },
    // {
    //     label: 'PageYaml',
    //     path: url.YAML(),
    //     exact: true,
    //     // component: PageChat,
    //     component: Loadable({
    //         loader: () =>
    //             import(
    //                 /* webpackPrefetch: true, webpackChunkName: "PageYaml" */
    //                 `./components/PageYaml/PageYaml`
    //             ),
    //         loading,
    //     }),
    // },
];
