import { __assign } from "tslib";
import { mmUid } from 'mm-ts-utils';
import { domainAction } from './domain-redux';
import { ARBITRARY_ID_APP_MODAL, DOMAIN_TYPE_ARBITRARY } from '../../consts';
import { AppModalClass } from '../_core/components/AppModal/AppModal';
export var APP_MODAL_TYPE_CONFIRM = 'confirm';
export var APP_MODAL_TYPE_ALERT = 'alert';
export var APP_MODAL_TYPE_PROMPT = 'prompt';
export var dispatchCreateAppModal = function (dispatch, appModal, onClose) {
    var _a, _b;
    if (appModal) {
        var _uid = mmUid();
        AppModalClass.callbacks.set(_uid, function (val) {
            if (onClose && typeof onClose === 'function') {
                onClose(val);
            }
        });
        dispatch(domainAction.mergeModelsPayload((_a = {},
            _a[DOMAIN_TYPE_ARBITRARY] = (_b = {},
                _b[ARBITRARY_ID_APP_MODAL] = __assign(__assign({}, appModal), { id: ARBITRARY_ID_APP_MODAL, _uid: _uid }),
                _b),
            _a)));
    }
    else {
        dispatch(domainAction.deleteModelByEntityTypeAndId(DOMAIN_TYPE_ARBITRARY, ARBITRARY_ID_APP_MODAL));
    }
};
export var dispatchDestroyAppModal = function (dispatch) {
    return dispatchCreateAppModal(dispatch, null);
};
export var alertModal = function (dispatch, appModal) {
    return new Promise(function (resolve, reject) {
        dispatchCreateAppModal(dispatch, __assign(__assign({}, appModal), { type: APP_MODAL_TYPE_ALERT }), resolve);
    });
};
export var promptModal = function (dispatch, appModal) {
    return new Promise(function (resolve, reject) {
        dispatchCreateAppModal(dispatch, __assign(__assign({}, appModal), { type: APP_MODAL_TYPE_PROMPT }), resolve);
    });
};
export var confirmModal = function (dispatch, appModal) {
    return new Promise(function (resolve, reject) {
        dispatchCreateAppModal(dispatch, __assign(__assign({}, appModal), { type: APP_MODAL_TYPE_CONFIRM }), resolve);
    });
};
