import * as React from 'react';
import { FormattedMessage } from 'react-intl';
/**
 * Namespace factory wrap
 * @param namespace
 * @constructor
 */
export var I18nMsgNs = function (namespace) {
    return function (props) {
        return (React.createElement(I18nMsg, { id: "".concat(namespace, ".").concat(props.id), defaultMessage: props.defaultMessage, values: props.values }));
    };
};
/**
 * Actual low level intl component wrap
 * @param props
 * @constructor
 */
export var I18nMsg = function (props) {
    var id = props.id, defaultMessage = props.defaultMessage, values = props.values;
    return React.createElement(FormattedMessage, { id: id, defaultMessage: defaultMessage, values: values });
};
