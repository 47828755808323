import produce from 'immer';
/*********************************************************************************
 * ACTION TYPES
 ********************************************************************************/
export var sessionActionType = {
    SET_KEYVAL: 'session/SET_KEYVAL',
    RESET_SESSION: 'session/RESET_SESSION',
};
/*********************************************************************************
 * ACTIONS (ACTION CREATORS)
 ********************************************************************************/
export var sessionAction = {
    setSessionKeyValue: function (key, value) { return ({
        type: sessionActionType.SET_KEYVAL,
        payload: { key: key, value: value },
    }); },
    resetSession: function () { return ({
        type: sessionActionType.RESET_SESSION,
    }); },
};
/*********************************************************************************
 * SELECTORS
 ********************************************************************************/
//
export var sessionSelector = {
    getSession: function (state) { return state.session; },
    getSessionValue: function (state) { return function (key) { return state.session[key]; }; },
};
var createInitialSessionState = function () { return ({}); };
export var sessionReducer = produce(function (state, action) {
    switch (action.type) {
        case sessionActionType.SET_KEYVAL:
            return _setKeyVal(state, action.payload);
        case sessionActionType.RESET_SESSION:
            return createInitialSessionState();
    }
}, createInitialSessionState());
/*********************************************************************************
 * HELPERS
 ********************************************************************************/
var _setKeyVal = function (state, payload) {
    if (payload.value !== undefined) {
        state[payload.key] = payload.value;
    }
};
