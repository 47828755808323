import { __awaiter, __extends, __generator } from "tslib";
import { mmReplaceMap } from 'mm-ts-utils';
import { APIADAPTER_FACTORY_EXPOSE_KEY } from '../../../consts';
import { ADAPTER_NAMES } from '../types';
import { getAdapterName, getClientConfig, saveAsClientConfigToSession, } from './app-storage';
import { createDebugLogger, parseUrl } from './app-utils';
var _apiInstance = null;
var debug = createDebugLogger('api-helpers');
/**
 * Returns factory function for given adapter name.
 */
export var getApiAdapterFactoryByName = function (name) {
    if (!window[APIADAPTER_FACTORY_EXPOSE_KEY][name]) {
        throw new Error("Adapter not found (name: ".concat(name, ")"));
    }
    return window[APIADAPTER_FACTORY_EXPOSE_KEY][name];
};
/**
 * DRY... (samotne sharovanie instancie nema extra zmysel)
 */
export var api = function () {
    if (!_apiInstance) {
        var cfg = getClientConfig().CHAT_API;
        var adapterName = getAdapterName();
        var currentUrl = parseUrl();
        // config cleanup, so adapter will get only relevant
        cfg.URL = mmReplaceMap(cfg.URL || cfg.URL_FALLBACK || '', {
            '{protocol}': currentUrl.protocol,
            '{hostname}': currentUrl.hostname,
            '{port}': currentUrl.port,
        });
        if (adapterName === ADAPTER_NAMES.GOODBOT_FLOW && cfg.CONFIG_HASH) {
            cfg.URL = cfg.URL.replace(/[^/]+$/, cfg.CONFIG_HASH);
        }
        debug("Constructing adapter '".concat(adapterName, "' with URL: ").concat(cfg.URL));
        delete cfg.URL_FALLBACK;
        _apiInstance = getApiAdapterFactoryByName(adapterName)(cfg);
    }
    return _apiInstance;
};
/**
 * API adapter "goodbot-flow" is able to change its model in runtime.
 * This is done in the project https://github.com/Born-Digital-AI/builder.
 * The new config hash is replaced in the given URL for the API adapter.
 * The URL has this format: https://example.com/flows/{configHash}
 * @param configHash
 */
export var replaceApiAdapterConfigHash = function (configHash) {
    var adapterName = getAdapterName();
    if (adapterName !== ADAPTER_NAMES.GOODBOT_FLOW) {
        throw new Error("Use adapter 'goodbot-flow' instead of '".concat(adapterName, "' to replace config hash."));
    }
    saveAsClientConfigToSession({ CHAT_API: { CONFIG_HASH: configHash } });
    _apiInstance = null;
};
/**
 * Custom exception for the HTTP errors from the API adapter.
 */
var ApiHttpError = /** @class */ (function (_super) {
    __extends(ApiHttpError, _super);
    function ApiHttpError(response) {
        var _this = _super.call(this, "".concat(response.status, " for ").concat(response.request.url)) || this;
        _this.name = 'ApiHttpError';
        _this.response = response;
        return _this;
    }
    return ApiHttpError;
}(Error));
export { ApiHttpError };
/**
 * Makes sure at least one of the `data` and `errors` field is there.
 * @param json
 */
export var apiAssertJsonApiEnvelope = function (json) {
    if (json.data === void 0 && json.errors === void 0) {
        throw new Error('Json-api response not recognized');
    }
};
/**
 * Checks the response state and normalizes returned payload.
 * @param resp
 * @param rowFilter
 */
export var apiHandleRawJsonApiResponse = function (resp, rowFilter) { return __awaiter(void 0, void 0, void 0, function () {
    var json;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apiHandleRawJsonResponse(resp)];
            case 1:
                json = _a.sent();
                return [2 /*return*/, apiNormalizeJsonApiResponse(json, rowFilter)];
        }
    });
}); };
/**
 * Checks the response state and returns payload if it's successful.
 * @param resp
 */
export var apiHandleRawJsonResponse = function (resp) { return __awaiter(void 0, void 0, void 0, function () {
    var json;
    return __generator(this, function (_a) {
        if (resp.status >= 400) {
            throw new ApiHttpError(resp);
        }
        json = resp.data;
        apiAssertJsonApiEnvelope(json);
        // toto je pre pripad kedy server vratil 200, ale obalka obsahuje error
        if (json.errors) {
            throw new Error('Api error');
        }
        return [2 /*return*/, json];
    });
}); };
/**
 * Just rethrows the exception for now :|
 * Would be nice if it throws ApiHttpError or RequestError later.
 * See https://github.com/axios/axios#handling-errors
 */
export var apiHandleRespError = function (e) {
    throw e;
};
/**
 * quick-n-dirty, minimal needed work only
 *
 * @param {JSONApiEnvelope} jsonapi
 * @param {(row: JSONApiData) => any} rowFilter
 * @returns {any}
 */
export var apiNormalizeJsonApiResponse = function (jsonapi, rowFilter) {
    var out = {};
    ['data', 'included'].forEach(function (_k) {
        if (jsonapi[_k]) {
            var rows = Array.isArray(jsonapi[_k]) ? jsonapi[_k] : [jsonapi[_k]];
            rows.forEach(function (row) {
                // custom filter? (e.g. for meta parsing... etc)
                if (rowFilter) {
                    row = rowFilter(row);
                }
                if (!row) {
                    return;
                }
                var type = row.type, id = row.id, attributes = row.attributes, relationships = row.relationships;
                // sanity check: id and attributes.id MUST match...
                if (attributes && id !== attributes.id) {
                    throw new Error("Json api data integrity failure (id don't match)");
                }
                // https://redux.js.org/basics/reducers
                // We suggest that you keep your state as normalized as possible,
                // without any nesting. Keep every entity in an object stored with
                // an ID as a key, and use IDs to reference it from other entities,
                // or lists. Think of the app's state as a database.
                out[type] = out[type] || {};
                var r = (out[type][id] = Object.assign(out[type][id] || {}, attributes || {}));
                // if there is no `relationships`, skipping in output completely
                if (relationships === void 0) {
                    delete r.__relationships__;
                }
                // reset if falsy
                else if (!relationships) {
                    r.__relationships__ = {};
                }
                else {
                    // hm... tu rozmyslam nad flatnutim struktury, kde namiesto:
                    // relationships = {tag: [{type: tag, id: 3}]} budem davat nieco ako:
                    // relationships = {tag: [3]}
                    r.__relationships__ = Object.keys(relationships).reduce(function (memo, k) {
                        memo[k] = memo[k] || [];
                        var rel = Array.isArray(relationships[k])
                            ? relationships[k]
                            : [relationships[k]];
                        rel.forEach(function (o) { return memo[k].push(o.id); });
                        return memo;
                    }, {});
                }
            });
        }
    });
    // tak trosku hack... potrebujeme si docasne ulozit meta, lebo to mozeme neskor
    // potrebovat...
    out.__meta__ = jsonapi.meta || {};
    return out;
};
