import { __assign, __awaiter, __extends, __generator } from "tslib";
import cn from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import { mmUid } from 'mm-ts-utils';
import ms from 'ms';
import * as React from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import * as Scroll from 'react-scroll';
import { ChatMessageType } from '../../../../_library/domain-base/BaseChatMessage';
import { wsJoinRoom, wsLeaveRoom } from '../../../../_library/utils/app-ws-room-utils';
import { CUSTOM_MESSAGE_NEXT_INPUT_VALUE_FORCE_NONE, CUSTOM_MESSAGE_ROOM_MARKER__SET_ENTITIES, CUSTOM_MESSAGE_TYPE_MARKER, DUMMY_AUDIO, NOTIFICATION_ID__THREAD_CHANGE, } from '../../../../consts';
import { BytesizeIcon } from '../../../_core/components/BytesizeIcon';
import { config } from '../../../_core/config';
import { getClientConfig } from '../../../_core/utils/app-storage';
import { createDebugLogger } from '../../../_core/utils/app-utils';
import { I18nMsgNs } from '../../../_core/utils/i18n-helpers';
import { domainSelector } from '../../../domain/domain-redux';
import { dispatchOppositeParty } from '../../../domain/opposite-party';
import { identitySelector } from '../../../identity/identity-redux';
import { _detectOppositeParty } from '../../actions/_detect-opposite-party';
import { chatAction } from '../../chat-redux';
import { ChatMessage } from '../../ChatMessage';
import ChatInput from '../ChatInput/ChatInput';
import { Messages } from '../Messages/Messages';
import { TypingUsers } from '../TypingUsers/TypingUsers';
import './ChatThread.scss';
import ChatThreadDebugInfo from './ChatThreadDebugInfo';
import { ToastContainer } from 'react-toastify';
var Msg = I18nMsgNs('ChatThread');
// POZNAMKY K PROBLEMU SO SCROLOVANIM SU DOLE...
var ChatThreadClass = /** @class */ (function (_super) {
    __extends(ChatThreadClass, _super);
    function ChatThreadClass(props) {
        var _this = this;
        var _a, _b;
        _this = _super.call(this, props) || this;
        _this.debug = createDebugLogger('ChatThread');
        _this.$scrollContainer = React.createRef();
        _this.$botAvatar = React.createRef();
        _this.$userAvatar = React.createRef();
        _this.scrollToBottom = function () {
            setTimeout(function () {
                Scroll.animateScroll.scrollToBottom({
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    containerId: 'scrollContainer',
                    isDynamic: true,
                    // neviem co to interne robi, ale zda sa, ze to fixlo issue...
                    ignoreCancelEvents: true,
                });
            }, 100);
        };
        _this.submitMessage = function (value, type, meta) {
            if (meta === void 0) { meta = {}; }
            return ChatThreadClass.submitMessage(_this.props, value, type, meta);
        };
        _this.getOptionsOnly = function (messages) {
            var options = messages.filter(function (message) {
                var _a;
                if ((_a = message === null || message === void 0 ? void 0 : message.meta) === null || _a === void 0 ? void 0 : _a.options) {
                    return message.meta.options.length > 0;
                }
                else {
                    return false;
                }
            });
            options = __assign({}, options[options.length - 1]);
            if (!options)
                return [];
            options.body = '';
            return [options];
        };
        _this.determineNoOptions = function (messages) {
            var _a;
            if (messages.length && ((_a = messages[messages.length - 1].meta) === null || _a === void 0 ? void 0 : _a.options)) {
                return messages[messages.length - 1].meta.options.length === 0;
            }
            return true;
        };
        _this.isTypingIndicatorHidden = localStorage.getItem('chatBubbleTypingIndicatorHidden') === 'true';
        if (!_this.props.threadId) {
            throw new Error('Missing expected threadId prop');
        }
        _this.handleOnAction = _this.handleOnAction.bind(_this);
        _this.handleOnActive = _this.handleOnActive.bind(_this);
        _this.handleOnIdle = _this.handleOnIdle.bind(_this);
        _this.msgSound = new Audio();
        _this.isChatInputHidden = (_b = (_a = _this.props.meta) === null || _a === void 0 ? void 0 : _a.named_entities) === null || _b === void 0 ? void 0 : _b.isChatInputHidden;
        return _this;
    }
    ChatThreadClass.prototype.componentDidMount = function () {
        var _this = this;
        var _a = getClientConfig(), TIMEOUT_NOTIFICATION_TIME = _a.TIMEOUT_NOTIFICATION_TIME, TIMEOUT_TIME = _a.TIMEOUT_TIME, MSG_SOUND_SRC = _a.MSG_SOUND_SRC, IS_MSG_SOUND_ENABLED = _a.IS_MSG_SOUND_ENABLED;
        this.idleTimer = null;
        this.notificationTimeout =
            TIMEOUT_NOTIFICATION_TIME && Number(ms(TIMEOUT_NOTIFICATION_TIME));
        this.timeout = TIMEOUT_TIME && Number(ms(TIMEOUT_TIME));
        this.msgSound.src =
            MSG_SOUND_SRC ||
                'https://notificationsounds.com/storage/sounds/file-sounds-1148-juntos.mp3';
        this.useSound = Boolean(IS_MSG_SOUND_ENABLED) || false;
        document.body.addEventListener('click', function () {
            return _this.unlockAudio(_this.msgSound.src);
        });
        document.body.addEventListener('touchstart', function () {
            return _this.unlockAudio(_this.msgSound.src);
        });
    };
    ChatThreadClass.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a, _b, _c, _d;
        var prevLength = (prevProps.messages || []).length;
        var length = (this.props.messages || []).length;
        if (snapshot) {
            this.debug("Setting scrollTop from snapshot: ".concat(snapshot));
            this.$scrollContainer.current.scrollTop = snapshot;
        }
        if (typeof ((_b = (_a = prevProps === null || prevProps === void 0 ? void 0 : prevProps.meta) === null || _a === void 0 ? void 0 : _a.named_entities) === null || _b === void 0 ? void 0 : _b.isChatInputHidden) !== 'undefined') {
            this.isChatInputHidden = (_d = (_c = prevProps.meta) === null || _c === void 0 ? void 0 : _c.named_entities) === null || _d === void 0 ? void 0 : _d.isChatInputHidden;
        }
        if (prevLength < length) {
            try {
                if (this.props.lastMessage.authorType !== ChatMessage.AUTHOR_TYPE_USER &&
                    this.useSound) {
                    this.msgSound.play();
                }
            }
            catch (error) {
                console.error(error);
            }
            this.scrollToBottom();
        }
        var oppositeParty = _detectOppositeParty(this.props.messages);
        if (oppositeParty === 'agent' && this.props.isAgent === false) {
            dispatchOppositeParty(this.props.dispatch, true);
        }
        var lastMessage = this.props.messages[this.props.messages.length - 1];
        if (lastMessage) {
            var parentElement = this.$botAvatar.current.parentElement;
            var botMessageGroups = Array.from(parentElement.children).filter(function (element) {
                var type = element.getAttribute('data-group-label');
                return (element.className.includes(config.css.B('-message-group')) &&
                    type === 'other');
            });
            var lastBotMessageGroup = botMessageGroups[botMessageGroups.length - 1];
            if (lastBotMessageGroup) {
                var newBotAvatarPosition = lastBotMessageGroup.offsetTop + lastBotMessageGroup.offsetHeight;
                this.$botAvatar.current.style.marginTop = newBotAvatarPosition + 'px';
            }
            var userMessageGroups = Array.from(parentElement.children).filter(function (element) {
                var type = element.getAttribute('data-group-label');
                return (element.className.includes(config.css.B('-message-group')) &&
                    type === 'user');
            });
            var lastUserMessageGroup = userMessageGroups[userMessageGroups.length - 1];
            if (lastUserMessageGroup) {
                var newUserAvatarPosition = lastUserMessageGroup.offsetTop + lastUserMessageGroup.offsetHeight;
                this.$userAvatar.current.style.marginTop = newUserAvatarPosition + 'px';
                this.$userAvatar.current.style.opacity = '1';
            }
        }
    };
    ChatThreadClass.prototype.unlockAudio = function (originalSrc) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.msgSound.src = DUMMY_AUDIO;
                        return [4 /*yield*/, this.msgSound.play()];
                    case 1:
                        _a.sent();
                        this.msgSound.pause();
                        this.msgSound.currentTime = 0;
                        this.msgSound.src = originalSrc;
                        document.body.removeEventListener('click', this.unlockAudio);
                        document.body.removeEventListener('touchstart', this.unlockAudio);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatThreadClass.prototype.handleOnAction = function (event) {
        console.log('Idle timer interrupted. Activity captured.', event);
    };
    ChatThreadClass.prototype.handleOnActive = function () {
        clearTimeout(this.timeoutId);
        console.log('Remaining time of idle timer = ', this.idleTimer.getRemainingTime());
    };
    ChatThreadClass.prototype.handleOnIdle = function () {
        var _this = this;
        var _a = getClientConfig(), TIMEOUT_NOTIFICATION_MSG = _a.TIMEOUT_NOTIFICATION_MSG, TIMEOUT_MSG = _a.TIMEOUT_MSG;
        this.submitMessage('Notification', ChatMessageType.WIDGET, {
            message: TIMEOUT_NOTIFICATION_MSG,
            hideInput: false,
        });
        this.timeoutId = setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.idleTimer.pause();
                        return [4 /*yield*/, this.submitMessage('Notification', ChatMessageType.END, {
                                message: TIMEOUT_MSG,
                                hideInput: true,
                            })];
                    case 1:
                        _b.sent();
                        this.submitMessage(JSON.stringify({
                            type: CUSTOM_MESSAGE_TYPE_MARKER,
                            room: CUSTOM_MESSAGE_ROOM_MARKER__SET_ENTITIES,
                            payload: (_a = {},
                                _a[CUSTOM_MESSAGE_NEXT_INPUT_VALUE_FORCE_NONE] = true,
                                _a.named_entities = {
                                    RPT_Inactive_Chat: 'yes',
                                },
                                _a),
                        }), ChatMessageType.HIDDEN);
                        return [2 /*return*/];
                }
            });
        }); }, this.timeout);
    };
    ChatThreadClass.prototype.getSnapshotBeforeUpdate = function (prevProps, prevState) {
        var prevLength = (prevProps.messages || []).length;
        var length = (this.props.messages || []).length;
        // Are we adding new items to the list?
        // Capture the scroll position so we can adjust scroll later.
        if (prevLength < length) {
            return this.$scrollContainer.current.scrollTop;
        }
        return null;
    };
    ChatThreadClass.prototype.componentWillUnmount = function () {
        var threadId = this.props.threadId;
        threadId && wsLeaveRoom(threadId);
    };
    ChatThreadClass.prototype.render = function () {
        var _this = this;
        var _a = this.props, identity = _a.identity, threadId = _a.threadId, messages = _a.messages, threadUpdated = _a.threadUpdated, chatBubbleTypingIndicatorHidden = _a.chatBubbleTypingIndicatorHidden, meta = _a.meta;
        var B = config.css.B('-chat-thread');
        threadId && wsJoinRoom(threadId);
        var options = this.getOptionsOnly(messages);
        var noOptions = this.determineNoOptions(messages);
        return (React.createElement("div", { className: cn(B) },
            this.timeout && (React.createElement(IdleTimer, { ref: function (ref) {
                    _this.idleTimer = ref;
                }, timeout: this.notificationTimeout, onActive: this.handleOnActive, onIdle: this.handleOnIdle, onAction: this.handleOnAction, debounce: 250 })),
            React.createElement(ChatThreadDebugInfo, { threadId: threadId }),
            React.createElement("div", { className: cn("".concat(B, "__messages")), id: "scrollContainer", ref: this.$scrollContainer },
                React.createElement("div", { className: cn("".concat(B, "__avatar")), "data-type": "bot", ref: this.$botAvatar },
                    React.createElement("i", { "data-author-type": 'bot' },
                        React.createElement(BytesizeIcon, { name: 'code', size: "smallest" }))),
                React.createElement("div", { className: cn("".concat(B, "__avatar")), "data-type": "user", ref: this.$userAvatar },
                    React.createElement("i", { "data-author-type": 'user' },
                        React.createElement(BytesizeIcon, { name: 'user', size: "smallest" }))),
                React.createElement(Messages, { identity: identity, messages: messages, threadLastUpdated: threadUpdated, submitMessage: this.submitMessage, scrollToBottom: this.scrollToBottom }),
                !chatBubbleTypingIndicatorHidden && (React.createElement("div", { className: cn("".concat(B, "__typing_indicator")) },
                    React.createElement(TypingUsers, { parentContainer: this.$scrollContainer, threadId: threadId, 
                        // excludeUserIds={[(identity || ({} as any)).id].filter((v) => !!v)}
                        threadLastUpdated: threadUpdated, scrollToBottom: this.scrollToBottom })))),
            !isEmpty(options) && (React.createElement("div", { className: "".concat(cn("".concat(B, "__options_bounding")), " ").concat(noOptions ? 'hidden' : '') },
                React.createElement(Messages, { identity: identity, messages: options, threadLastUpdated: threadUpdated, submitMessage: this.submitMessage, 
                    // onSize={this.onSize}
                    scrollToBottom: this.scrollToBottom }))),
            React.createElement("div", { className: cn("".concat(B, "__footer_bounding")) },
                !this.props.chatInputHidden && !this.isChatInputHidden && (React.createElement("div", { className: cn("".concat(B, "__input")) },
                    React.createElement(ChatInput, { threadId: threadId, submitMessage: this.submitMessage, inputPlaceholder: this.props.inputPlaceholder, meta: meta }))),
                React.createElement("div", { className: cn("".concat(B, "__footer")) },
                    "powered by",
                    ' ',
                    React.createElement("a", { target: "_blank", href: "https://borndigital.ai/", title: "AI-Driven Automation of Human Conversations | Automatizace lidsk\u00E9 \u0159e\u010Di | Born Digital AI" }, "born DIGITAL"))),
            React.createElement(ToastContainer, { limit: 1 })));
    };
    ChatThreadClass.submitMessage = function (_a, value, type, meta) {
        var dispatch = _a.dispatch, identity = _a.identity, threadId = _a.threadId;
        if (meta === void 0) { meta = {}; }
        var msg = new ChatMessage({
            id: mmUid(),
            thread_id: threadId,
            user_id: (identity || {}).id,
            body: "".concat(value).trim(),
            type: type || undefined,
            meta: meta || {},
        });
        return dispatch(chatAction.saveMessage(identity, msg));
    };
    return ChatThreadClass;
}(React.Component));
export { ChatThreadClass };
// MM POZNAMKA: povodne som sa snazil optimalizovat cez nizsiu referenciu (kvoli
// react node compare) ale ukazuje sa to problematicke (napr. pri skrolovani), lebo
// prevProps.messages.length a this.props.messages.length nikdy nie su rozdielne...
// celkovo to asi nebol dobry napad a je to slepa ulicka...
// let _messages = [];
var mapStateToProps = function (state, ownProps) {
    var threadId = ownProps.threadId;
    // const thread = domainSelector.getThread(state)(threadId);
    var _messages = domainSelector.getThreadMessages(state)(threadId);
    // for custom hackings (if needed)...
    if (typeof ChatThreadClass.messageTransformerOrFilter === 'function') {
        _messages = ChatThreadClass.messageTransformerOrFilter(_messages);
    }
    // vid komentar vyssie
    // note: nizsie tance nikdy netrigernu render kedze robime nad referenciou (ale
    // to tak chceme)
    // _messages.length = 0; // truncate
    // _messages.push(..._msgs); // push
    //
    var threadUpdateNotified = 0;
    var n = domainSelector.getClientNotification(NOTIFICATION_ID__THREAD_CHANGE)(state);
    if (n && n.payload.thread_id === threadId && n.created !== void 0) {
        threadUpdateNotified = new Date(n.created).valueOf();
    }
    // prettier-ignore
    var lastUpdated = _messages.reduce(function (memo, m) { return Math.max(memo, new Date(m.created).valueOf()); }, 0);
    var threadUpdated = Math.max(lastUpdated, threadUpdateNotified);
    var lastMsg = domainSelector.getLastThreadMessage(state)(threadId);
    var lastMessage = new ChatMessage(__assign({}, lastMsg));
    var isAgent = domainSelector.getOppositeParty(state);
    // cokolvek sa stalo vyssie, ak nesedi pocet, urcite chceme prerenderovat...
    // toto moze mat zmysel pri roznych transient messagoch, ktore sa pridavaju
    // na klientovi mimo `last_updated` db hodnot (isTyping a pod)...
    // if (ownProps.messagesCount !== _messages.length) {
    //     threadUpdated = new Date().valueOf();
    // }
    var uiState = domainSelector.getUIState(state);
    var meta = domainSelector.getMeta(state);
    return {
        threadId: threadId,
        identity: identitySelector.getIdentity(state),
        threadUpdated: threadUpdated,
        lastMessage: lastMessage,
        messages: _messages,
        isAgent: isAgent,
        chatInputHidden: uiState.chat_input_hidden,
        chatBubbleTypingIndicatorHidden: uiState.chatBubbleTypingIndicatorHidden,
        meta: meta,
    };
};
var mapDispatchToProps = function (dispatch, ownProps) {
    return ({
        dispatch: dispatch,
    });
};
export var ChatThread = connect(mapStateToProps, mapDispatchToProps)(ChatThreadClass);
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default ChatThread;
/**
 * UPDATE - NAKONIEC JE VSETKO INAK: ukazuje sa, ze chyba bola v definicii Loadable
 * kde sa zakazdym vytvarala nova instancia komponentov...
 *
 * NIZSIE NEPLATI... LOADABLE JE OK
 *
 * MISC POZNAMKY K PROBLEMU SO SKROLOVANIM, KYM NEZABUDNEM... (nemusia suvisiet spolu - vela issues sa prelinalo)
 * - tym, ze renderujeme cez Loadable, vsetko je asynchronne a v case renderu
 *   ma wrapper nulovu vysku, preto veci z dokumentacie ku getSnapshotBeforeUpdate
 *   nefunguju
 * - rovnako scroll pozicia je pred updatom vzdy nula
 * - povodna (premature) optimalizacia v mapStateToProps (referencia na messages) sa ukazala byt
 *   uplne kontraproduktivna, lebo prevProps.messages.length a this.props.messages.length
 *   nikdy neboli rozdielne
 * - skusal som to VSELIJAK ohybat ale neuspesne.
 * - dalsi problem bol, ze jednotlive <Messages .../> boli renderovane iba cez
 *   Array.map a teda nemali spolocneho rodica, ktoremu sa dala merat vyska (co bol
 *   easy fix, ale chvilu to trvalo uvedomit si)
 * - problemy boli jednak scrollovat a jednak neskocit na 0 pred zacatim noveho scrollu
 * - skok na nula sa ukazal byt v enter keyDown, kedy sa nerobil submit spravne...
 *
 * Nedokonale, ale good enough riesenie aktualne je:
 * 1. pocuvame na onScroll a ukladame si priebezne poziciu (vsetko in memory)
 * 2. vyssie dva udaje su uz dost na to aby sme vedeli reagovat - ak dojde k zvacseniu
 *    vysky a zaroven mame ulozenu poslednu scroll poziciu, tak hned po renderi
 *    setneme scrollTo (co setne prave vyrenderovani kontajner na rovnaku poziciu
 *    a teda neskoci na 0) a az nasledne zavolame animateScroll
 *    ... je tu vsak potencialne issue, ze ak by nejaky widget mal menit vysku, mozno
 *    to bude treba fixovat
 *
 * Problem co ostava je napr. ked message obsahuju obrazky, ktore sa nacitavaju... tam
 * je meranie vysky problematicke
 */
