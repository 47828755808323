import { __extends } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { Button } from 'reactstrap';
import { config } from '../../../../_core/config';
import { createDebugLogger } from '../../../../_core/utils/app-utils';
import { I18nMsgNs } from '../../../../_core/utils/i18n-helpers';
import ExternalLink from './ExternalLink/ExternalLink';
import './Options.scss';
import { checkHidden, parseMarkdownOption } from './parse-markdown-option';
var Msg = I18nMsgNs('Options');
var Options = /** @class */ (function (_super) {
    __extends(Options, _super);
    function Options(props) {
        var _this = _super.call(this, props) || this;
        _this.debug = createDebugLogger('Options');
        /**
         * @param e
         * @param value
         * @param utterance "optional value used as actual utterance to goodbot over the
         *                   wire... Hidden in UI. Saved in message.meta container."
         */
        _this.submitValueAsChatMessage = function (e, value, utterance) {
            e.preventDefault();
            if (typeof _this.props.submitMessage === 'function') {
                _this.setState({ disabled: true });
                _this.props.submitMessage(value, null, { utterance: utterance });
                return;
            }
            console.warn("Unable to submit '".concat(value, "'"));
        };
        _this.showMore = function () {
            _this.setState({ showMore: !_this.state.showMore });
        };
        _this.state = {
            disabled: false,
            hiddenIsActive: checkHidden(_this.props.options),
            showMore: false,
        };
        return _this;
    }
    Options.prototype.render = function () {
        var _this = this;
        var _a = this.props, options = _a.options, disabled = _a.disabled, data = _a.data, message = _a.message;
        var B = config.css.B('-message-options');
        var authorType = message.authorType;
        var activeButtons = [];
        var hiddenButtons = [];
        var _maybeRenderExternalLink = function (label, idx) {
            if (!data || (typeof data === 'object' && !('url_buttons' in data))) {
                return null;
            }
            if (Object.keys(data.url_buttons)[0] === label) {
                var link = data.url_buttons[label];
                return (React.createElement(ExternalLink, { key: idx, title: label, description: link.description || '', url: link.url || '', image: link.image || '', disabled: disabled || _this.state.disabled, redirect: link.redirect || false, onClick: function (e) { return _this.submitValueAsChatMessage(e, label); } }));
            }
            else {
                return null;
            }
        };
        options.forEach(function (optionInput, idx) {
            var _a = parseMarkdownOption(optionInput), label = _a.label, color = _a.color, customClassName = _a.customClassName, value = _a.value, img = _a.img;
            var externalLink = _maybeRenderExternalLink(label, idx);
            if (externalLink) {
                return externalLink;
            }
            var buttons = !_this.state.hiddenIsActive || customClassName !== 'hidden'
                ? activeButtons
                : hiddenButtons;
            buttons.push(React.createElement(Button, { key: idx, className: cn("".concat(B, "__button"), "".concat(B, "__button--").concat(color), customClassName), onClick: function (e) { return _this.submitValueAsChatMessage(e, label, value); }, value: value, disabled: disabled || _this.state.disabled },
                img && React.createElement("img", { src: img, alt: "" }),
                label));
        });
        var linkButton = (React.createElement("div", { style: {
                display: 'flex',
                justifyContent: 'flex-end',
            } },
            React.createElement(Button, { className: cn("".concat(B, "__toggle-button")), onClick: this.showMore }, this.state.showMore ? (React.createElement(Msg, { id: 'showLess' })) : (React.createElement(Msg, { id: 'showMore' })))));
        return (React.createElement("div", { className: cn(B), "data-disabled": this.state.disabled, "data-author-type": authorType },
            activeButtons,
            this.state.hiddenIsActive && (React.createElement(React.Fragment, null,
                this.state.showMore && hiddenButtons,
                linkButton))));
    };
    Options.defaultProps = {
        disabled: false,
    };
    return Options;
}(React.Component));
export { Options };
export default Options;
