import * as React from 'react';
import { getLoadableComponent } from '../../../../_library/utils/get-loadable-component';
import { config } from '../../config';
import { getClientConfig } from '../../utils/app-storage';
import { ErrorBoundary } from '../ErrorBoundary';
import './DefaultLayout.scss';
export function DefaultLayout(_a) {
    var h1 = _a.h1, children = _a.children;
    var B = config.css.B('-layout');
    var segments = getSegments(children);
    var cfg = getClientConfig();
    h1 = h1 || cfg.PROJECT_TITLE;
    var HeaderSegment = function () {
        var _a;
        if ((_a = cfg.HEADER) === null || _a === void 0 ? void 0 : _a.DISABLED) {
            return null;
        }
        // ak je `header` segment explicitny null, tak nic, inak defaultujeme do <Header />
        // to je pre pripady, kedy nechcem header vobec davat (co je tu chapane ako vynimka)
        if (segments.header === null) {
            return null;
        }
        return (React.createElement("div", { className: "".concat(B, "__headerbox") },
            React.createElement(ErrorBoundary, null, segments.header || getLoadableComponent('Header'))));
    };
    return (React.createElement(React.Fragment, null,
        h1 && React.createElement("h1", { className: "sr-only" }, h1),
        React.createElement("section", { className: B },
            React.createElement(HeaderSegment, null),
            React.createElement("div", { className: "".concat(B, "__mainbox") },
                React.createElement(ErrorBoundary, null, segments.main)))));
}
function getSegments(children) {
    var segments = {};
    React.Children.forEach(children, function (child) {
        if (!child.props.label) {
            console.warn("Expecting layout segment with 'label' prop.");
        }
        else if (segments[child.props.label]) {
            console.warn("Layout segment labeled as '".concat(child.props.label, "' already defined."));
        }
        else {
            segments[child.props.label] = child.props.children;
        }
    });
    return segments;
}
