import { combineReducers } from 'redux';
import { pmaCreateReducer, pmaCreateSelector, } from '../_core/utils/pma-reducer';
import { createThread } from './actions/create-thread';
import { readThread } from './actions/read-thread';
import { saveMessage } from './actions/save-message';
/*********************************************************************************
 * MODULE ACTION TYPES
 ********************************************************************************/
export var chatActionType = {
    FETCH_READ_THREAD: 'chat/FETCH_READ_THREAD',
    FETCH_CREATE_THREAD: 'chat/FETCH_CREATE_THREAD',
    //
    FETCH_SAVE_MESSAGE: 'chat/FETCH_SAVE_MESSAGE',
    // message
    // CLIENT_SAVE_MESSAGE: 'chat/CLIENT_SAVE_MESSAGE',
};
/*********************************************************************************
 * MODULE ACTIONS (ACTION CREATORS)
 ********************************************************************************/
export var chatAction = {
    readThread: readThread,
    createThread: createThread,
    saveMessage: saveMessage,
};
/*********************************************************************************
 * MODULE SELECTORS
 ********************************************************************************/
export var chatSelector = {
    pmaReadThread: function (state) { return pmaCreateSelector(state.chat.pmaReadThread); },
    pmaCreateThread: function (state) { return pmaCreateSelector(state.chat.pmaCreateThread); },
    pmaSaveMessage: function (state) { return pmaCreateSelector(state.chat.pmaSaveMessage); },
};
export var chatReducer = combineReducers({
    pmaCreateThread: pmaCreateReducer(chatActionType.FETCH_CREATE_THREAD),
    pmaReadThread: pmaCreateReducer(chatActionType.FETCH_READ_THREAD),
    pmaSaveMessage: pmaCreateReducer(chatActionType.FETCH_SAVE_MESSAGE),
});
