import { combineReducers } from 'redux';
import { sessionAction, sessionActionType, sessionReducer, sessionSelector, } from '../session/session-redux';
import { identityAction, identityActionType, identityReducer, identitySelector, } from '../identity/identity-redux';
import { domainAction, domainActionType, domainReducer, domainSelector, } from '../domain/domain-redux';
import { chatAction, chatActionType, chatReducer, chatSelector, } from '../chat/chat-redux';
import { deleteFromSessionStorage } from './utils/app-storage';
export var STORAGE_KEY_REDUX = 'redux-state';
export var actionType = {
    identity: identityActionType,
    session: sessionActionType,
    domain: domainActionType,
    chat: chatActionType,
};
// a.k.a. action creators
export var action = {
    identity: identityAction,
    session: sessionAction,
    domain: domainAction,
    chat: chatAction,
};
export var selector = {
    identity: identitySelector,
    session: sessionSelector,
    domain: domainSelector,
    chat: chatSelector,
};
var appReducer = combineReducers({
    identity: identityReducer,
    session: sessionReducer,
    domain: domainReducer,
    chat: chatReducer,
});
export var ACTION_REDUX_RESET = { type: 'RESET_STORE' };
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
export var reducer = function (state, action) {
    if (action.type === ACTION_REDUX_RESET.type) {
        deleteFromSessionStorage(STORAGE_KEY_REDUX);
        state = void 0;
    }
    return appReducer(state, action);
};
