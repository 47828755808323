import { __assign, __awaiter, __generator } from "tslib";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';
import 'core-js/stable';
import merge from 'lodash-es/merge';
import throttle from 'lodash-es/throttle';
import { mmEscapeRegExp, mmGetRandomAlphaNumStr, mmParseBoolean, mmReplaceMap, } from 'mm-ts-utils';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import * as cs from 'react-intl/locale-data/cs';
// sem doplnit vsetky podporovane jazyky - toto nie je datovo velke, cize sme ok
import * as sk from 'react-intl/locale-data/sk';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { initializeExternals } from '../../client-externals/initialize-externals';
import { APIADAPTER_URL_MAP_EXPOSE_KEY, HTTP_TIMEOUT_IN_MILLIS } from './consts';
import './index.scss';
import App from './modules/_core/components/App/App';
import AppModal from './modules/_core/components/AppModal/AppModal';
import { BubbleChannel } from './modules/_core/components/BubbleChannel';
import { ErrorBoundary } from './modules/_core/components/ErrorBoundary';
import { HeartBeat } from './modules/_core/components/HeartBeat';
import { WsChannel } from './modules/_core/components/WsChannel';
import { STORAGE_KEY_REDUX } from './modules/_core/reducer';
import { factoryStore } from './modules/_core/store';
import { getAdapterName, getClientConfig, getFromSessionStorage, saveToSessionStorage, setClientConfig, } from './modules/_core/utils/app-storage';
import { isInIframe } from './modules/_core/utils/app-utils';
import '../../client-externals/jablotron/jablotron.scss';
if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.SENTRY_ENVIRONMENT,
        normalizeDepth: 10,
        maxValueLength: 1000,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.1,
    });
}
axios.defaults.validateStatus = function () { return true; };
axios.defaults.timeout = HTTP_TIMEOUT_IN_MILLIS;
// toto treba pre vsetky vyssie supported jazyky okrem `en`, ktory je defaultny
addLocaleData(sk);
addLocaleData(cs);
var $root = document.getElementById('root');
/**
 *
 */
var initialState = getFromSessionStorage(STORAGE_KEY_REDUX) || {};
/**
 * Number of milliseconds after that is guaranteed
 * the Redux state is persisted to `sessionStorage`.
 */
var STATE_PERSISTENCE_DELAY_IN_MILLIS = 1000;
/**
 *
 */
export var store = factoryStore(initialState);
if (mmParseBoolean(process.env.SAVE_REDUX_STATE_TO_SESSION_STORAGE)) {
    store.subscribe(throttle(function () { return saveToSessionStorage(STORAGE_KEY_REDUX, store.getState()); }, STATE_PERSISTENCE_DELAY_IN_MILLIS));
}
//
// const browser = detectBrowser();
/**
 *
 */
var applyCustomRulesToConfig = function (clientConfig) {
    try {
        var customConfig = clientConfig;
        // LET'S DANCE: we are supporting (bottom overwrites upper):
        //  - root config
        //  - __IF(_NOT)_IN_IFRAME__ block
        //  - __LOCATION_HREF_REGEX__ (array of blocks, first wins) overwrites
        //    (with corresponding __IF(_NOT)_IN_IFRAME__ block)
        var locationBasedConfig = {};
        if (customConfig.__LOCATION_HREF_REGEX__ &&
            Array.isArray(customConfig.__LOCATION_HREF_REGEX__)) {
            var arr = customConfig.__LOCATION_HREF_REGEX__;
            dance: for (var i = 0; i < arr.length; i++) {
                var _block = arr[i];
                if (!Array.isArray(_block.__regex__)) {
                    _block.__regex__ = [_block.__regex__];
                }
                // first match wins
                for (var j = 0; j < _block.__regex__.length; j++) {
                    var rx = new RegExp(mmEscapeRegExp(_block.__regex__[j]), 'i');
                    // NOTE: must NOT use widow.parent.localtion due to cross-frame
                    // security reasons (app won't init)
                    if (rx.test(window.location.href)) {
                        console.log("[Client] Found location based config for ".concat(rx));
                        locationBasedConfig = _block.__config__;
                        break dance;
                    }
                }
            }
        }
        // overwrite "default" by actual context
        if (isInIframe()) {
            customConfig = merge(customConfig, customConfig.__IF_IN_IFRAME__ || {}, locationBasedConfig, locationBasedConfig.__IF_IN_IFRAME__ || {});
        }
        else {
            customConfig = merge(customConfig, customConfig.__IF_NOT_IN_IFRAME__ || {}, locationBasedConfig, locationBasedConfig.__IF_NOT_IN_IFRAME__ || {});
        }
        // (somewhat ugly): remove deep all underscored keys (considered as "internal" like)
        customConfig = JSON.parse(JSON.stringify(customConfig, function (k, v) { return (k.match(/^_/) ? void 0 : v); }));
        return setClientConfig(merge(clientConfig, customConfig));
    }
    catch (e) {
        console.warn("Could not add custom rules to config: ".concat(e));
        return clientConfig;
    }
};
/**
 *
 */
var fetchI18n = function (clientConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var locale, messages, _url, r, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                locale = (clientConfig.I18N_LOCALE || 'en').trim();
                messages = {};
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                _url = mmReplaceMap(clientConfig._I18N_DATA_JSON_URL, {
                    '{{locale}}': locale,
                    '{{rand}}': mmGetRandomAlphaNumStr(8),
                    '{{hash}}': window.WEBPACK_BUNDLE_HASH,
                });
                if (!_url) return [3 /*break*/, 3];
                return [4 /*yield*/, axios.get(_url)];
            case 2:
                r = _a.sent();
                if (r.status < 400) {
                    try {
                        messages = r.data;
                    }
                    catch (e) {
                        console.warn("i18n data for '".concat(locale, "' malformed or not found"));
                    }
                }
                else {
                    console.warn("i18n data for '".concat(locale, "' not found"));
                }
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                e_1 = _a.sent();
                // we are not going to crash just because of the i18n
                console.warn(e_1);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/, { locale: locale, messages: messages }];
        }
    });
}); };
/**
 *
 */
var fetchApiAdapterAndCustomCss = function (clientConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var adapter, _url, urls, customCssUrls;
    return __generator(this, function (_a) {
        adapter = getAdapterName();
        _url = window[APIADAPTER_URL_MAP_EXPOSE_KEY][adapter];
        if (!_url) {
            throw new Error("Adapter url for '".concat(adapter, "' missing in config"));
        }
        urls = [_url];
        customCssUrls = clientConfig.CUSTOM_CSS_URLS;
        if (customCssUrls) {
            if (!Array.isArray(customCssUrls)) {
                customCssUrls = [customCssUrls];
            }
            urls = urls
                .concat(customCssUrls)
                .filter(Boolean)
                .filter(function (v) { return v && v !== '${CONFIG_CUSTOM_CSS_URLS}'; }); // hack: neinterpolovany placeholder
        }
        urls = urls.map(function (v) {
            return mmReplaceMap(v, {
                '{{rand}}': mmGetRandomAlphaNumStr(8),
                '{{hash}}': window.WEBPACK_BUNDLE_HASH,
            });
        });
        // kvoli dynamickemu BASE_URL toto nemozeme riesit cez webpack ale az tu
        if (process.env.NODE_ENV === 'development') {
            urls.push('static/react.development.js');
            urls.push('static/react-dom.development.js');
        }
        else {
            urls.push('static/react.production.min.js');
            urls.push('static/react-dom.production.min.js');
        }
        // prettier-ignore
        return [2 /*return*/, new Promise(function (resolve, reject) {
                window.loadjs(urls, {
                    success: function () { return resolve(_url); },
                    error: function (depsNotFound) {
                        // js povazujeme za kriticke, css nie
                        var critical = depsNotFound.filter(function (v) { return /\.js$/i.test(v); }); // conventional, but essentially naive
                        if (critical.length) {
                            reject("loadjs: error loading critical dependencies (".concat(depsNotFound, ")"));
                        }
                        else {
                            console.warn("loadjs: some deps not found (".concat(depsNotFound.join(', '), ")"));
                            resolve(_url);
                        }
                    },
                });
            })];
    });
}); };
/**
 *
 */
var render = function (locale, messages) {
    if (locale === void 0) { locale = 'en'; }
    return __awaiter(void 0, void 0, void 0, function () {
        var routerBaseName, base, Application;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!$root) {
                        return [2 /*return*/, panic('Root DOM node not found')];
                    }
                    routerBaseName = '/';
                    base = document.getElementsByTagName('base')[0];
                    if (base) {
                        routerBaseName = base.getAttribute('href');
                    }
                    return [4 /*yield*/, initializeExternals()];
                case 1:
                    _a.sent();
                    Application = process.env.SENTRY_DSN ? Sentry.withProfiler(App) : App;
                    // prettier-ignore
                    ReactDOM.render(React.createElement(Provider, { store: store },
                        React.createElement(IntlProvider, { locale: locale, messages: messages },
                            React.createElement(Router, { basename: routerBaseName },
                                React.createElement(React.Fragment, null,
                                    React.createElement(ErrorBoundary, null,
                                        React.createElement(Application, null)),
                                    React.createElement(ErrorBoundary, null,
                                        React.createElement(AppModal, null)),
                                    React.createElement(ErrorBoundary, null,
                                        React.createElement(WsChannel, null)),
                                    React.createElement(ErrorBoundary, null,
                                        React.createElement(BubbleChannel, null)),
                                    React.createElement(ErrorBoundary, null,
                                        React.createElement(HeartBeat, null)))))), $root);
                    return [2 /*return*/];
            }
        });
    });
};
/**
 * @param errmsg
 */
function panic(errmsg) {
    if ($root) {
        $root.style.color = 'red';
        $root.style.padding = '20px';
        $root.innerHTML = 'Sorry, we were unable to initialize the application.';
    }
    console.error(errmsg);
}
/**
 * @param customCssVars
 * @private
 */
function _setCssVarsFromConfig(customCssVars) {
    // teraz, este pred tym, nez loadneme custom css musime zaregistrovat css vars...
    // kvoli EDGE-u budeme trosku hackovat
    var _cssVars = new Map();
    Object.keys(customCssVars || {}).forEach(function (k) {
        if (/^--/.test(k)) {
            // tento zapis je elgantny, ale, zda sa, nefunguje v EDGE...
            // document.documentElement.style.setProperty(k, clientConfig.CUSTOM_CSS_VARS[k]);
            // cize:
            _cssVars.set(k, customCssVars[k]);
        }
    });
    if (_cssVars.size) {
        try {
            var css_1 = "";
            _cssVars.forEach(function (v, k) { return (css_1 += "".concat(k, ":").concat(v, ";")); });
            var link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = 'data:text/css;base64,' + window.btoa(":root{".concat(css_1, "}"));
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        catch (e) {
            console.error(e);
        }
    }
}
// main
(function () { return __awaiter(void 0, void 0, void 0, function () {
    var clientConfig, _a, locale, messages, customI18n, e_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                clientConfig = getClientConfig();
                // ak nemame clientConfig tak dovi-dopo
                if (!clientConfig) {
                    return [2 /*return*/, panic('Undefined clientConfig')];
                }
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 5]);
                clientConfig = applyCustomRulesToConfig(clientConfig);
                // config hned pouzijeme...
                _setCssVarsFromConfig(clientConfig.CUSTOM_CSS_VARS);
                return [4 /*yield*/, Promise.all([
                        fetchI18n(clientConfig),
                        fetchApiAdapterAndCustomCss(clientConfig),
                    ])];
            case 2:
                _a = (_b.sent())[0], locale = _a.locale, messages = _a.messages;
                customI18n = (clientConfig.I18N_CUSTOM || {})[locale] || {};
                messages = __assign(__assign({}, messages), customI18n);
                console.log("Used adapter: ".concat(getAdapterName()));
                return [4 /*yield*/, render(locale, messages)];
            case 3:
                _b.sent();
                return [3 /*break*/, 5];
            case 4:
                e_2 = _b.sent();
                panic(e_2);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); })();
// import * as serviceWorker from './serviceWorker';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
