import { __assign } from "tslib";
import * as React from 'react';
import * as Loadable from 'react-loadable';
import LOADABLE_COMPONENTS_MAP_EXTERNALS from '../../../../client-externals/loadable-components-map-externals';
import { LOADABLE_COMPONENTS_MAP } from '../../loadable-components-map';
// DOLEZITE!!! inak vracia stale fresh instance co je na hovno
var _loadableCache = new Map();
// prettier-ignore
export var defaultLoading = function (props) {
    if (props.error) {
        return React.createElement("span", { className: "loadable-component-loading loadable-component-error" }, props.error.toString());
    }
    else if (props.timedOut) {
        return React.createElement("span", { className: "loadable-component-loading loadable-component-timedout" }, "Still loading...");
    }
    else if (props.pastDelay) {
        return React.createElement("span", { className: "loadable-component-loading" });
    }
    // Sometimes components load really quickly (<200ms) and the loading screen
    // only quickly flashes on the screen.
    // A number of user studies have proven that this causes users to perceive
    // things taking longer than they really have. If you don't show anything,
    // users perceive it as being faster.
    else {
        // null nemoze byt, lebo page-transition erroruje potom
        return React.createElement("span", null);
    }
};
/**
 * @param cmpName
 */
export var getLoadableComponentLoader = function (cmpName) {
    // IMPORTANT: check for EXTERNALS FIRST and THEN use DEFAULT...
    return LOADABLE_COMPONENTS_MAP_EXTERNALS[cmpName] || LOADABLE_COMPONENTS_MAP[cmpName];
};
/**
 * @param cmpName
 * @param props
 * @param loading
 */
export var getLoadableComponent = function (cmpName, props, loading) {
    if (props === void 0) { props = {}; }
    var loader = getLoadableComponentLoader(cmpName);
    if (loader) {
        // pozerat do cache je dolezite, lebo inak by vracal stale novu instanciu
        // co by znamenalo fresh render...
        var Cmp = _loadableCache.get(loader);
        if (!Cmp) {
            Cmp = Loadable({
                loader: loader,
                loading: loading || defaultLoading,
            });
            _loadableCache.set(loader, Cmp);
        }
        return React.createElement(Cmp, __assign({}, props));
    }
    // prettier-ignore
    return (React.createElement("small", { style: { display: 'block', background: '#ffc', color: 'darkred' } },
        React.createElement("b", null, cmpName),
        " component not found!"));
};
