import { __extends } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP_MODAL_TYPE_ALERT, dispatchDestroyAppModal, } from '../../../domain/app-modal';
import { domainSelector } from '../../../domain/domain-redux';
import { config } from '../../config';
import { createDebugLogger } from '../../utils/app-utils';
import { I18nMsgNs } from '../../utils/i18n-helpers';
import './AppModal.scss';
ReactModal.setAppElement('#root');
var _isFn = function (v) { return typeof v === 'function'; };
var Msg = I18nMsgNs('AppModal');
var AppModalClass = /** @class */ (function (_super) {
    __extends(AppModalClass, _super);
    function AppModalClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debug = createDebugLogger('AppModal');
        _this.state = {
            value: false,
        };
        _this.onAfterOpen = function () { };
        _this.onAfterClose = function () {
            _this.setState({ value: false }); // internal reset
        };
        _this.doClose = function () {
            dispatchDestroyAppModal(_this.props.dispatch);
            if (_this.props.appModal) {
                var _uid = _this.props.appModal._uid;
                var cb = AppModalClass.callbacks.get(_uid);
                if (cb && typeof cb === 'function') {
                    cb(_this.state.value); // Critical part!
                    AppModalClass.callbacks.delete(_uid);
                }
            }
        };
        _this.handleOK = function () {
            _this.setState({ value: true }, _this.doClose);
        };
        _this.handleCancel = function () {
            _this.setState({ value: false }, _this.doClose);
        };
        _this._tns = function (id, values, defaultMessage) {
            return _this.props.intl.formatMessage({ id: "AppModal.".concat(id), defaultMessage: defaultMessage }, values);
        };
        return _this;
    }
    // todo
    // handleInput = (value) => {
    //     this.setState({ value });
    // };
    AppModalClass.prototype.render = function () {
        var B = config.css.B('-app-modal');
        var isOpen = !!this.props.appModal;
        var appModal = this.props.appModal;
        var renderer = AppModalClass.renderer;
        if (!appModal) {
            return null;
        }
        if (_isFn(renderer)) {
            return renderer(this);
        }
        // prettier-iggnore
        return (React.createElement(ReactModal, { isOpen: isOpen, onAfterOpen: this.onAfterOpen, onRequestClose: this.doClose, onAfterClose: this.onAfterClose, 
            //
            shouldCloseOnOverlayClick: false, 
            // contentLabel="Modal window"
            //
            portalClassName: B, overlayClassName: cn("".concat(B, "__overlay")), className: cn("".concat(B, "__contentbox")), bodyOpenClassName: cn("".concat(B, "__body--open")), htmlOpenClassName: cn("".concat(B, "__html--open")) },
            React.createElement("div", { className: cn("".concat(B, "__content")) },
                React.createElement("div", { className: cn("".concat(B, "__header")) }, this._renderHeader()),
                React.createElement("div", { className: cn("".concat(B, "__body")) }, this._renderBody()),
                React.createElement("div", { className: cn("".concat(B, "__controls")) }, this._renderControls()))));
    };
    AppModalClass.prototype._renderHeader = function () {
        if (_isFn(AppModalClass.rendererHeader)) {
            return AppModalClass.rendererHeader(this);
        }
        var appModal = this.props.appModal;
        // povolujeme html...
        if (appModal.title) {
            return React.createElement("span", { dangerouslySetInnerHTML: { __html: appModal.title } });
        }
        return React.createElement(Msg, { id: "title_".concat(appModal.type) });
    };
    AppModalClass.prototype._renderBody = function () {
        if (_isFn(AppModalClass.rendererBody)) {
            return AppModalClass.rendererBody(this);
        }
        // todo: add prompt type
        var appModal = this.props.appModal;
        // povolujeme html...
        if (appModal.body) {
            return React.createElement("span", { dangerouslySetInnerHTML: { __html: appModal.body } });
        }
        return React.createElement(Msg, { id: "body_".concat(this.props.appModal.type) });
    };
    AppModalClass.prototype._renderControls = function () {
        if (_isFn(AppModalClass.rendererControls)) {
            return AppModalClass.rendererControls(this);
        }
        var appModal = this.props.appModal;
        var B = config.css.B('-app-modal');
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn("".concat(B, "__controls--ok")) },
                React.createElement("button", { onClick: this.handleOK, className: "btn btn-ok" }, appModal.labelOK || React.createElement(Msg, { id: "OK" }))),
            appModal.type !== APP_MODAL_TYPE_ALERT && (React.createElement("div", { className: cn("".concat(B, "__controls--cancel")) },
                React.createElement("button", { onClick: this.handleCancel, className: "btn btn-cancel" }, appModal.labelCancel || React.createElement(Msg, { id: "Cancel" }))))));
    };
    AppModalClass.callbacks = new Map();
    return AppModalClass;
}(React.Component));
export { AppModalClass };
var mapStateToProps = function (state, ownProps) {
    var appModal = domainSelector.getAppModal(state);
    return {
        appModal: appModal,
    };
};
var mapDispatchToProps = function (dispatch, ownProps) {
    return ({
        dispatch: dispatch,
    });
};
export var AppModal = withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AppModalClass)));
// lebo react-loadable s defaultnym exportom vyzaduje menej roboty
export default AppModal;
