import { ChatMessage } from '../ChatMessage';
import { ChatActionHelper } from './ChatActionHelper';
import { BaseChatMessage } from '../../../_library/domain-base/BaseChatMessage';
/**
 * Detect if opposite party is agent or bot
 * @param messages
 * @private
 */
export var _detectOppositeParty = function (messages) {
    // use custom external logic if needed...
    if (typeof ChatActionHelper.detectOppositeParty === 'function') {
        return ChatActionHelper.detectOppositeParty(messages);
    }
    var hasAgentMessage;
    for (var i = messages.length - 1; i >= 0; i--) {
        var msg = new ChatMessage(messages[i]);
        if (msg.is_bot || msg.is_agent) {
            hasAgentMessage = msg.is_agent;
            break;
        }
    }
    if (hasAgentMessage) {
        return BaseChatMessage.AUTHOR_TYPE_AGENT;
    }
    return BaseChatMessage.AUTHOR_TYPE_BOT;
};
